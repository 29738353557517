import React, { useState } from 'react';
import { get, isEmpty, isEqual } from 'lodash';
import { Col, message, Row, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import { IconButton } from 'components/Buttons';
import { LoadingOutlined } from '@ant-design/icons';
import ExcelIcon from 'images/ExcelIcon';
import EmptyWidget from '../../Components/EmptyWidget';
import WidgetTable from '../../../../components/Dashboard/WidgetTable';
import { LargeWidgetCard } from '../../styled';
import SCol from '../../../../components/Standard/SCol';
import SRow from '../../../../components/Standard/SRow';
import { widgetInfoTextSetter, widgetNameSetter } from '../../utils';
import { WidgetTitle } from '../../../../components/WidgetTitle';
import SettingsModal from '../../Components/SettingsModal';
import WidgetViewLastUpdate from '../../../../components/Dashboard/WidgetViewLastUpdate';
import { getChecklistItems, prepareColumns, prepareRows } from './prepareTableData';
import { useTableUserFieldAnalyticsExport } from './hooks';

const TableUserFieldAnalytics = ({ widgetData, loadingWidgetId, isWarningWidget }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, type, filters, name } = widgetData;
  const { startExport, loadingExport } = useTableUserFieldAnalyticsExport();
  const usersByIds = useSelector(state => state.usersResource.byIds);
  const isEmptyWidgetData = isEmpty(widgetData);
  const customFields = useSelector(state => state.userCustomFieldsResource.byIds);
  const widgetsAnalytics = useSelector(state => state.dashboardAnalytics.byIds[id], isEqual);
  const widgetValue = get(widgetsAnalytics, 'data', []);
  const widgetMeta = get(widgetsAnalytics, 'meta', []);
  const lastItem = Array.isArray(widgetValue) ? widgetValue[widgetValue.length - 1] : null;
  const dateLastUpdate = lastItem ? lastItem.dateLastUpdate : null;

  const checklistItems = getChecklistItems(customFields, widgetValue);

  const tableData = {
    tableColumns: prepareColumns({
      checklistItems,
      filters
    }),
    tableRows: prepareRows({
      widgetValue: Object.values(widgetValue),
      usersByIds,
      t
    })
  };

  const getSortString = ({ sorter }) => {
    if (!sorter.order) return '';

    const getSorterKey = () => {
      return sorter.columnKey;
    };

    return sorter.order === 'ascend' ? getSorterKey() : `-${getSorterKey()}`;
  };

  const onChange = async ({ sorter }) => {
    const { columnKey } = sorter;

    await dispatch(
      updateDashboardWidget({
        id,
        type,
        filters,
        sort: columnKey ? getSortString({ sorter, viewMode: filters?.viewMode }) : ''
      })
    );
  };

  const isEmptyWidget = isEmptyWidgetData || isWarningWidget || isEmpty(widgetValue);

  return (
    <>
      {isEmptyWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={24} key={id}>
          <LargeWidgetCard>
            <Row type="flex" justify="space-between" align="top">
              <SCol span={8}>
                <SRow type="flex" gutter={[6, 0]} justify="start" alignItems="center" wrap={false}>
                  <SCol flex="auto" display="flex" alignItems="center">
                    <Tooltip title={widgetNameSetter({ name, type })}>
                      <WidgetTitle>{widgetNameSetter({ name, type })}</WidgetTitle>
                    </Tooltip>
                    {widgetInfoTextSetter({ type })}
                  </SCol>
                </SRow>
              </SCol>
              <SCol display="flex" justifyContent="flex-end" span={8} alignItems="center">
                <IconButton
                  tooltip={{ title: t('dashboardPage.widget.buttons.export') }}
                  button={{
                    icon: loadingExport ? <LoadingOutlined spin /> : <ExcelIcon />,
                    marginTop: '2px',
                    onClick: () =>
                      widgetMeta?.total_count >= 5000
                        ? message.warning(t('messages.warning.limitExportReviews'))
                        : startExport({
                            id,
                            type,
                            filters,
                            widgetMeta,
                            checklistItems,
                            t
                          }),
                    size: 'icon'
                  }}
                />
                <SettingsModal widgetData={widgetData} />
                <WidgetViewLastUpdate widgetData={widgetData} dateLastUpdate={dateLastUpdate} />
              </SCol>
            </Row>
            <WidgetTable
              columns={tableData.tableColumns}
              dataSource={tableData.tableRows}
              loading={loadingWidgetId === id || loadingExport}
              size="small"
              bordered
              showSorterTooltip={false}
              scroll={{ x: 1500, y: 452 }}
              style={{ marginTop: 8 }}
              rowClassName={record => (record.key === 'header' ? 'table-row-light' : '')}
              pagination={false}
              onChange={(pagination, filters, sorter) => onChange({ sorter })}
            />
          </LargeWidgetCard>
        </Col>
      )}
    </>
  );
};

export default TableUserFieldAnalytics;
