import { Row } from 'antd';
import ColumnTitle from 'components/ColumnTitle';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { customFieldsResource } from 'redux/resources/customFields';
import Text from 'antd/lib/typography/Text';
import { AddColumnIcon, ColumnPreview } from './styled';

const CustomFieldsColumns = ({
  addColumn,
  customFieldsByKeys,
  checklistDefinitionsIds,
  questionsChecklist,
  currentPage,
  setCurrentPage,
  totalPages,
  setTotalPages,
  customFieldsColumns
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const [loadingMore, setLoadingMore] = useState(false);
  const scrollContainerRef = useRef(null);

  const loadMore = async () => {
    if (loadingMore) {
      return;
    }

    if (currentPage === totalPages) {
      return;
    }

    setLoadingMore(true);
    try {
      const response = await dispatch(
        customFieldsResource.operations.load({
          organization_id: organizationId,
          page_number: currentPage + 1,
          page_size: 10
        })
      );

      if (isEmpty(totalPages)) {
        setTotalPages(response?.meta?.totalPages);
      }

      setCurrentPage(prevPage => prevPage + 1);
    } finally {
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 5 && !loadingMore) {
          loadMore();
        }
      }
    };

    const currentContainer = scrollContainerRef.current;
    if (currentContainer) {
      currentContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loadingMore, currentPage]);

  return (
    <div>
      <Row type="flex" align="middle" justify="space-between" style={{ height: '40px' }}>
        <Text strong>{t('clientInteractionsPage.drawer.columns.customFieldsColumnsTitle')}</Text>
      </Row>

      <div ref={scrollContainerRef} style={{ overflowY: 'auto', maxHeight: '400px' }}>
        {customFieldsColumns.map(column => (
          <Row key={column} gutter={[8, 8]} style={{ margin: '-4px 0' }}>
            <ColumnPreview span={24} onClick={() => addColumn(column)}>
              <ColumnTitle
                columnType={column}
                customFieldsByKeys={customFieldsByKeys}
                checklistDefinitionsIds={checklistDefinitionsIds}
                questionsChecklist={questionsChecklist}
                width="80%"
              />
              <AddColumnIcon />
            </ColumnPreview>
          </Row>
        ))}

        {currentPage === totalPages && (
          <Row type="flex" align="middle" justify="center" style={{ height: '30px' }}>
            <Text strong>Все поля загружены</Text>
          </Row>
        )}
      </div>
    </div>
  );
};

export default CustomFieldsColumns;
