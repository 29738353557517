import { isEqual } from 'lodash';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import { exportWidgetData } from 'pages/DashboardPage/utils';
import { prepareRows } from './prepareTableData';

export const TableUserFieldAnalyticsContext = React.createContext({
  loadingExport: false,
  startExport: () => {}
});

export const TableUserFieldAnalyticsProvider = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loadingExport, setLoadingExport] = useState(false);
  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);

  const startExport = async ({ id, type, filters, widgetMeta, checklistItems, t }) => {
    const res = await dispatch(
      updateDashboardWidget({
        id,
        type,
        filters,
        page: {
          number: 1,
          size: widgetMeta.total_count
        }
      })
    );

    const columns = [
      {
        title: t('dashboardPage.tableUserFieldAnalytics.columnsNames.operatorId'),
        dataIndex: 'operatorId',
        key: 'operatorId'
      },
      {
        title: t('dashboardPage.tableUserFieldAnalytics.columnsNames.communicationCount'),
        dataIndex: 'communicationCount',
        key: 'communicationCount'
      },
      {
        title: t('dashboardPage.tableUserFieldAnalytics.columnsNames.responsesCount'),
        dataIndex: 'responsesCount',
        key: 'responsesCount'
      },
      {
        title: t('dashboardPage.tableUserFieldAnalytics.columnsNames.fractionCount'),
        dataIndex: 'fractionCount',
        key: 'fractionCount'
      }
    ];

    const rows = prepareRows({
      widgetValue: res?.updatedAnalytics,
      usersByIds,
      t,
      isExport: true
    });

    const body = {
      widgetType: type,
      widgetData: {
        columns,
        rows
      }
    };

    const handleExport = () => {
      checklistItems.forEach(item => {
        if (!item) return;

        const scores = item?.scores || {};

        const scoresColumns = Object.values(scores).flatMap(score => {
          return [
            {
              title: score?.name,
              dataIndex: score?.id,
              key: score?.id,
              answer: true
            }
          ];
        });

        const answersCount = Object.keys(scoresColumns).length; // Количество ответов
        // Добавляем колонку кастомного поля
        columns.push({
          title: item?.name,
          dataIndex: item?.id,
          key: item?.id,
          type: 'custom_field',
          answersCount,
          scores: { ...scoresColumns } // Колонки ответов кастомного поля
        });
      });
      // Добавляем колонку Итого
      columns.push({
        title: t('general.result'),
        dataIndex: 'totalScore',
        key: 'totalScore'
      });
    };

    await handleExport();
    await exportWidgetData({ body, setLoadingExport });

    await dispatch(
      updateDashboardWidget({
        id,
        type,
        filters
      })
    );
  };
  return (
    <TableUserFieldAnalyticsContext.Provider value={{ loadingExport, startExport }}>
      {children}
    </TableUserFieldAnalyticsContext.Provider>
  );
};

export const useTableUserFieldAnalyticsExport = () => {
  const { loadingExport, startExport } = useContext(TableUserFieldAnalyticsContext);
  return { loadingExport, startExport };
};
