import React from 'react';
import { useSelector } from 'react-redux';
import { get, isEqual } from 'lodash';
import AiIcon from '../assets/images/ai-icon.svg';

const QolioAiIcon = ({ styles = {}, alwaysShow = false }) => {
  const organizationInfo = useSelector(state => state.organizationInfo.organization, isEqual);
  const canAiAnalysisClientInteractionsInOrganization = get(
    organizationInfo,
    'settings.ai.analysis.enabled',
    false
  );
  if (alwaysShow) {
    return <img src={AiIcon} alt="ai-icon" style={styles} />;
  }
  if (canAiAnalysisClientInteractionsInOrganization) {
    return <img src={AiIcon} alt="ai-icon" style={styles} />;
  }
  return '';
};

export default QolioAiIcon;
