import React, { useEffect, useState } from 'react';
import { message, Tooltip } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { get, isEmpty, isEqual } from 'lodash';
import ModalTask from '../../../pages/AiSettings/ModalTask';
import { aiSettingsResource } from '../../../redux/resources/aiSettings';
import IconButton from '../../IconButton';
import { customFieldsResource } from '../../../redux/resources/customFields';

const AnalysisCommunicationButton = ({ duration, isTextCommunicationPage = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const [isModalOpenAddorEditTask, setIsModalOpenAdorEditTask] = useState(false);
  const [isEditingData, setIsEditingData] = useState(false);
  const { id: paramsId } = useParams();
  const { currentCallId } = useSelector(state => state.uiRecordPlayer);
  const getCommunication = state => state.uiClientInteractionDrawer.communication;
  const communication = useSelector(
    getCommunication,
    (next, prev) => isEqual(next, prev) && !next?.loading
  );
  const textCommunicationId = useSelector(state =>
    get(state.textCommunicationsResource.byIds, paramsId, {})
  );
  const id = !isEmpty(currentCallId)
    ? currentCallId
    : communication?.type === 'text_communication'
    ? communication?.id
    : textCommunicationId?.id || null;

  const startTask = id => {
    try {
      dispatch(aiSettingsResource.operations.startAiSettings({ id })).then(() => {
        message.success(t('aiSettingsPage.operations.operationSuccessfully'));
      });
    } catch (error) {
      message.error(t('aiSettingsPage.operations.operationError'));
    } finally {
      setIsModalOpenAdorEditTask(false);
      setIsEditingData(false);
    }
  };

  useEffect(() => {
    dispatch(
      customFieldsResource.operations.load({
        organization_id: organizationId,
        pagination: false
      })
    );
  }, []);

  const showAnalyzeModal = () => {
    if (duration <= 25) {
      message.info(t('messages.success.transcriptionUnsupportedCallLength'), 7);
      return;
    }
    setIsModalOpenAdorEditTask(true);
  };

  return (
    <>
      <Tooltip title="Анализ коммуникации">
        <IconButton
          icon={<FileDoneOutlined />}
          shape="circle-outline"
          size="small"
          onClick={showAnalyzeModal}
          style={{ marginTop: 2 }}
          disabled={isEmpty(id)}
        />
      </Tooltip>

      {id && (
        <ModalTask
          isModalOpen={isModalOpenAddorEditTask}
          setIsModalOpen={setIsModalOpenAdorEditTask}
          isEditingData={isEditingData}
          setIsEditingData={setIsEditingData}
          isCommunicationCard
          startTask={startTask}
          idCommunicationCard={id}
          isTextCommunicationPage={isTextCommunicationPage}
        />
      )}
    </>
  );
};

export default AnalysisCommunicationButton;
