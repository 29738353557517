import { Copy, Trash2, Edit, MoreVertical } from 'react-feather';
import { Button, Col, Dropdown, Menu, message, Modal, Row, Select, Typography } from 'antd';
import EditableLabel from 'components/Inputs/EditableLabel';
import { PLANS_DASHBOARDS_COUNT_LIMIT } from 'core/utils/constants';
import { difference, get, sortBy } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { dashboardsResource } from 'redux/resources/dashboards';
import {
  createDashboard,
  deleteDashboard,
  loadDashboards,
  updateDashboard
} from 'redux/ui/dashboard/operations';
import { setActiveDashboard, toogleVisibleDrawer } from 'redux/ui/dashboard/reducer';
import Icon from 'components/Icon';
import {
  CreateButton,
  DeleteMenuItem,
  LimitCountDashboard,
  MoreDashboardInfoButton,
  StyledSelect
} from './styled';

let counter = 0;

const Options = ({
  dashboardsByIds,
  activeDashboardId,
  setActiveDashboard,
  activeDashboardName,
  createDashboard,
  updateDashboard,
  copyDashboardById,
  loadDashboards,
  deleteDashboard,
  toogleVisibleDrawer,
  currentUseerOrganizationPlanSlug
}) => {
  const [isEditing, setEditing] = useState(false);
  const { t } = useTranslation();

  const { Option } = Select;
  const { Text } = Typography;
  const { confirm } = Modal;

  const sortedDashboardsByIds = sortBy(dashboardsByIds, 'createdAt');

  const changeActiveDashboard = value => {
    if (activeDashboardId !== value) updateDashboard({ id: value, active: true });
  };

  const confirmDeleteDashboard = () => {
    toogleVisibleDrawer();
    confirm({
      title: t('dashboardPage.dashboard.drawer.dashboards.confirmDelete.title'),
      content: t('dashboardPage.dashboard.drawer.dashboards.confirmDelete.description'),
      onOk: async () => {
        try {
          await setActiveDashboard({
            id: difference(Object.keys(dashboardsByIds), [activeDashboardId])[0]
          });
          await deleteDashboard({ id: activeDashboardId });
          message.success(
            t('dashboardPage.dashboard.drawer.dashboards.messages.dashboardSuccessfullyDeleted')
          );
        } catch (error) {
          console.log(error);
          message.error(
            t('dashboardPage.dashboard.drawer.dashboards.messages.dashboardDeleteFailed')
          );
        }
      }
    });
  };

  const dashboardsNames = Object.values(dashboardsByIds).map(item => item.name);

  const createDashboardName = ({ supposedName }) => {
    if (dashboardsNames.includes(supposedName)) {
      counter++;
      return createDashboardName({
        supposedName: `${t('dashboardPage.dashboard.newDashboard')} ${dashboardsNames.length +
          counter}`
      });
    }
    counter = 0;
    return supposedName;
  };

  const createNewDashboard = async () => {
    try {
      const newDashboard = await createDashboard({
        name: createDashboardName({
          supposedName: `${t('dashboardPage.dashboard.newDashboard')} ${
            Object.keys(dashboardsByIds).length
          }`
        }),
        shared: false
      });
      if (newDashboard)
        message.success(t('dashboardPage.dashboard.messages.dashboardSuccessfullyCreated'));
    } catch (error) {
      console.log(error);
      message.error(t('dashboardPage.dashboard.messages.createDashboardFailed'));
    }
  };

  const copyDashboard = async () => {
    try {
      const response = await copyDashboardById({ id: activeDashboardId });
      if (response.status === 'success') {
        await loadDashboards();
        message.success(
          t('dashboardPage.dashboard.drawer.dashboards.messages.dashboardSuccessfullyCopied')
        );
      }
    } catch (error) {
      console.log(error);
      message.error(t('dashboardPage.dashboard.drawer.dashboards.messages.copyDashboardFailed'));
    }
  };

  const handleDashboardMenuClick = e => {
    if (e.key === 'editDashboard') setEditing(true);
    if (e.key === 'deleteDashboard') confirmDeleteDashboard();
    if (e.key === 'copyDashboard') copyDashboard();
  };

  const dashboardsLength = Object.keys(dashboardsByIds).length;

  const menu = (
    <Menu onClick={e => handleDashboardMenuClick(e)}>
      <Menu.Item key="editDashboard">
        <Icon icon={Edit} />
        <span>{t('dashboardPage.dashboard.drawer.dashboards.menu.edit')}</span>
      </Menu.Item>

      <Menu.Item key="copyDashboard">
        <Icon icon={Copy} />
        <span>{t('dashboardPage.dashboard.drawer.dashboards.menu.copy')}</span>
      </Menu.Item>

      <DeleteMenuItem key="deleteDashboard" style={{ color: 'var(--red_primary)' }}>
        <Icon icon={Trash2} />
        <span>{t('dashboardPage.dashboard.drawer.dashboards.menu.delete')}</span>
      </DeleteMenuItem>
    </Menu>
  );

  return (
    <>
      <Row type="flex" align="middle" justify="space-between" style={{ height: '30px' }}>
        <Text strong>{t('dashboardPage.dashboard.drawer.dashboards.title')}</Text>
      </Row>

      <Row type="flex" align="middle" justify="space-between">
        {isEditing ? (
          <Col span={24}>
            <EditableLabel
              initialState="edit"
              value={activeDashboardName}
              onSave={(name, { final }) => {
                setActiveDashboard({ name });
                if (final) {
                  updateDashboard({ id: activeDashboardId, name: activeDashboardName });
                  setEditing(false);
                }
              }}
            />
          </Col>
        ) : (
          <Col span={24}>
            <Row type="flex" align="middle" justify="space-between">
              <Col span={22}>
                <StyledSelect value={activeDashboardId} onChange={changeActiveDashboard}>
                  {Object.values(sortedDashboardsByIds).map(dashboard => (
                    <Option key={dashboard.id} value={dashboard.id}>
                      {dashboard.name}
                    </Option>
                  ))}
                </StyledSelect>
              </Col>
              <Col>
                <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
                  <MoreDashboardInfoButton icon={<Icon icon={MoreVertical} />} />
                </Dropdown>
              </Col>
            </Row>
          </Col>
        )}
      </Row>

      {dashboardsLength >= PLANS_DASHBOARDS_COUNT_LIMIT[currentUseerOrganizationPlanSlug] ? (
        <LimitCountDashboard
          message={
            <>
              <Text>{t('dashboardPage.dashboard.drawer.dashboards.messages.getPro')}</Text>
              <Button
                type="link"
                onClick={() => window.open('https://qolio.ru/annual_price')}
                style={{ padding: 0 }}
              >
                {t('dashboardPage.dashboard.drawer.dashboards.buttons.more')}
              </Button>
            </>
          }
          type="info"
        />
      ) : (
        <CreateButton block onClick={() => createNewDashboard()}>
          {t('dashboardPage.dashboard.drawer.dashboards.buttons.addDashboard')}
        </CreateButton>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const currentUseerOrganizationPlanSlug = get(
    state,
    'reduxTokenAuth.currentUser.attributes.user.organization.plan-slug',
    'start'
  );

  const { activeDashboardId, activeDashboardName } = state.uiDashboard;
  return {
    currentUseerOrganizationPlanSlug,
    activeDashboardId,
    activeDashboardName
  };
};

const mapDispatchToProps = {
  loadDashboards,
  setActiveDashboard,
  createDashboard,
  updateDashboard,
  copyDashboardById: dashboardsResource.operations.copyById,
  deleteDashboard,
  toogleVisibleDrawer
};

export default connect(mapStateToProps, mapDispatchToProps)(Options);
