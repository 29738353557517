import React from 'react';
import { Tooltip, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { stringify } from 'core/utils/queryString';
import GetUserPreview from 'components/UserPreview/GetUserPreview';
import { Trans } from 'react-i18next';
import { getUserName } from 'components/UserPreview/getUserName';
import { getFilters } from '../../utils';

const { Text } = Typography;

export const prepareColumns = ({ checklistItems, filters }) => {
  const itemsColumns = checklistItems.map((item, index) => {
    const hasScore = !isEmpty(item.scores);
    const className =
      index % 2 === 0 ? 'ant-table-cell-user-fields-color-1' : 'ant-table-cell-user-fields-color-2';

    return {
      title: (
        <Tooltip title={item.name} placement="topLeft">
          <Text>{item.name}</Text>
        </Tooltip>
      ),
      className,
      dataIndex: item.id,
      key: item.id,
      align: 'center',
      ellipsis: true,
      sorter: !hasScore,
      children: hasScore
        ? Object.values(item.scores).flatMap(child => {
            const isFraction = child.id && child.id.includes('-fraction');
            return [
              {
                title: (
                  <Tooltip
                    title={
                      isFraction ? (
                        <Trans i18nKey="dashboardPage.tableUserFieldAnalytics.columnsTooltips.fraction" />
                      ) : (
                        child.name
                      )
                    }
                    placement="topLeft"
                  >
                    <Text>{child.name}</Text>
                  </Tooltip>
                ),
                dataIndex: child.id,
                key: child.id,
                width: 100,
                align: 'center',
                sorter: true
              }
            ];
          })
        : undefined,
      render: text => {
        return {
          props: {
            style: {
              background: text?.color
            }
          },
          children: <Text>{text}</Text>
        };
      }
    };
  });

  return [
    {
      title: <Trans i18nKey="dashboardPage.tableUserFieldAnalytics.columnsNames.operatorId" />,
      dataIndex: 'operatorId',
      key: 'operatorId',
      width: 150,
      fixed: 'center',
      ellipsis: true,
      sorter: false,
      render: (text, record) => {
        if (record?.key === 'footer') {
          return <Trans i18nKey="dashboardPage.tableUserFieldAnalytics.rows.total" />;
        }
        return (
          <GetUserPreview
            userId={text}
            altName={record?.name}
            link={`/user/${text}/charts${stringify({
              filters: {
                ...getFilters(filters)
              }
            })}`}
          />
        );
      }
    },
    {
      title: (
        <Tooltip
          title={
            <Trans i18nKey="dashboardPage.tableUserFieldAnalytics.columnsTooltips.communicationCount" />
          }
          placement="topLeft"
        >
          <Text>
            <Trans i18nKey="dashboardPage.tableUserFieldAnalytics.columnsNames.communicationCount" />
          </Text>
        </Tooltip>
      ),
      dataIndex: 'communicationCount',
      key: 'communicationCount',
      width: 60,
      fixed: 'center',
      ellipsis: true,
      sorter: false,
      align: 'center',
      render: (text, record) => {
        return <Text>{record?.communicationCount}</Text>;
      }
    },
    {
      title: (
        <Tooltip
          title={
            <Trans i18nKey="dashboardPage.tableUserFieldAnalytics.columnsTooltips.responsesCount" />
          }
          placement="topLeft"
        >
          <Text>
            <Trans i18nKey="dashboardPage.tableUserFieldAnalytics.columnsNames.responsesCount" />
          </Text>
        </Tooltip>
      ),
      dataIndex: 'responsesCount',
      key: 'responsesCount',
      width: 80,
      fixed: 'center',
      ellipsis: true,
      sorter: false,
      align: 'center',
      render: (text, record) => {
        return <Text>{record?.responsesCount}</Text>;
      }
    },
    {
      title: (
        <Tooltip
          title={
            <Trans i18nKey="dashboardPage.tableUserFieldAnalytics.columnsTooltips.fractionCount" />
          }
          placement="topLeft"
        >
          <Text>
            <Trans i18nKey="dashboardPage.tableUserFieldAnalytics.columnsNames.fractionCount" />
          </Text>
        </Tooltip>
      ),
      dataIndex: 'fractionCount',
      key: 'fractionCount',
      width: 80,
      fixed: 'center',
      ellipsis: true,
      sorter: false,
      align: 'center',
      render: (text, record) => {
        const value = parseFloat(record?.fractionCount ?? 0);
        const fraction = !value ? null : `${value.toFixed(1)}%`;
        return <Text>{fraction}</Text>;
      }
    },
    ...itemsColumns,
    {
      title: <Trans i18nKey="dashboardPage.tableUserFieldAnalytics.columnsNames.totalScore" />,
      dataIndex: 'totalScore',
      key: 'totalScore',
      width: 100,
      fixed: 'right',
      align: 'center',
      ellipsis: true,
      sorter: true,
      render: text => {
        return <Text>{text}</Text>;
      }
    }
  ];
};

export const prepareRows = ({ widgetValue, usersByIds, t, isExport = false }) => {
  const getScore = item => {
    if (item?.type === 'fraction') {
      const value = parseFloat(item?.origin_score ?? 0);
      if (value === 0) {
        return null;
      }
      const percentage = value.toFixed(1);
      return `${percentage}%`;
    }
    const value = parseInt(item?.origin_score ?? 0, 10);
    if (value === 0) {
      return null;
    }
    return Math.floor(value);
  };

  const getScoreFraction = score => {
    const value = parseFloat(score ?? 0);
    const percentage = value.toFixed(1);
    return `${percentage}%`;
  };

  const calculateTotals = () => {
    const totals = {};

    Object.values(widgetValue).forEach(item => {
      if (!item || !item.scores) return;

      Object.entries(item.scores).forEach(([id, score]) => {
        if (score.type !== 'fraction') {
          totals[id] = (totals[id] || 0) + getScore(score);
        }
        if (score.type === 'fraction') {
          totals[id] = null;
        }
      });

      totals.communicationCount =
        (totals.communicationCount || 0) + parseInt(item?.communication_count ?? 0, 10);
      totals.responsesCount =
        (totals.responsesCount || 0) + parseInt(item?.responses_count ?? 0, 10);
    });

    const fraction = (totals?.responsesCount / totals?.communicationCount) * 100;
    const fractionPercentage = fraction.toFixed(1);
    totals.fractionCount = `${fractionPercentage}%`;

    return totals;
  };

  const rows = Object.values(widgetValue).map(item => {
    if (!item) return;

    const scores = item.scores || {};

    const childrenData = Object.fromEntries(
      Object.entries(scores).flatMap(([id, score]) => [[id, getScore(score)]])
    );

    return {
      key: `row-${Math.random()}`,
      operatorId: isExport
        ? getUserName({ user: usersByIds[item?.operator_id] })
        : item?.operator_id,
      communicationCount: item?.communication_count,
      responsesCount: item?.responses_count,
      fractionCount: getScoreFraction(item?.fraction_count),
      totalScore: item?.total_score,
      ...childrenData
    };
  }, []);

  const totalFieldsSum = calculateTotals();
  const ignoringColumns = ['communicationCount', 'responsesCount', 'fractionCount'];
  const totalScore = Object.entries(totalFieldsSum)
    .filter(([key]) => !ignoringColumns.includes(key) && !key.includes('-fraction'))
    .reduce((acc, [, score]) => acc + parseInt(score, 10), 0);

  const updatedTotalFieldsSum = Object.entries(totalFieldsSum).reduce((acc, [key, value]) => {
    if (key.endsWith('-fraction')) {
      const originalKey = key.slice(0, -'-fraction'.length);
      const originalValue = totalFieldsSum[originalKey];

      if (originalValue !== null && totalScore !== 0) {
        const fraction = (originalValue / totalScore) * 100;
        const fractionPercentage = fraction.toFixed(1);
        acc[key] = `${fractionPercentage}%`;
      } else {
        acc[key] = null;
      }
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});

  rows.push({
    key: 'footer',
    name: t('dashboardPage.tableUserFieldAnalytics.rows.total'),
    sorter: false,
    operatorId: t('dashboardPage.tableUserFieldAnalytics.rows.total'),
    totalScore,
    ...updatedTotalFieldsSum
  });

  return rows;
};

export const getChecklistItems = (customFields, widgetValue) => {
  return Object.values(customFields)
    .map(field => {
      const containsId = Object.values(widgetValue).some(operator =>
        Object.values(operator?.scores).some(answer => answer?.parent_id === field?.id)
      );
      if (!containsId) {
        return null;
      }

      const scores = {};
      for (const operator of Object.values(widgetValue)) {
        for (const [answerId, scoreData] of Object.entries(operator?.scores)) {
          if (scoreData?.parent_id === field.id) {
            if (!scores[answerId]) {
              scores[answerId] = {
                id: answerId,
                name: scoreData?.name
              };
            }
          }
        }
      }

      let isOneAnswer = false;
      let answerId = '';

      Object.values(widgetValue).map(item => {
        const scores = item?.scores;

        const scoreEntry = Object.entries(scores).find(
          ([key, score]) => score?.parent_id === field?.id
        );
        if (scoreEntry) {
          const [key, score] = scoreEntry;
          if (score?.oneAnswer) {
            isOneAnswer = true;
            answerId = key;
          }
        }
      });

      return {
        id: isOneAnswer ? answerId : field?.id,
        name: field?.name,
        scores
      };
    })
    .filter(item => item !== null);
};
