import { Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddColumnIcon, ColumnPreview } from './styled';
import ColumnTitle from '../../../../components/ColumnTitle';

const BasicColumns = ({
  basicColumns,
  addColumn,
  customFieldsByKeys,
  checklistDefinitionsIds,
  questionsChecklist
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Row type="flex" align="middle" justify="space-between" style={{ height: '40px' }}>
        <Text strong>{t('clientInteractionsPage.drawer.columns.title')}</Text>
      </Row>
      {basicColumns.map(column => {
        return (
          <Row key={column} gutter={[8, 8]} style={{ margin: '-4px 0' }}>
            <ColumnPreview span={24} onClick={() => addColumn(column)}>
              <ColumnTitle
                columnType={column}
                customFieldsByKeys={customFieldsByKeys}
                checklistDefinitionsIds={checklistDefinitionsIds}
                questionsChecklist={questionsChecklist}
                width="80%"
                thisIsCheckbox
              />
              <AddColumnIcon />
            </ColumnPreview>
          </Row>
        );
      })}
    </div>
  );
};

export default BasicColumns;
