export default {
  clientInteractionsPage: {
    clientInteractionsTable: {
      deactivatedUserLinkToCommunicationModal: {
        title: 'Эммм… данный пользователь не активен 😢',
        description: 'Для перехода к оценке, сделайте пользователя активным. ',
        button: 'Перейти в настройки',
        ok: 'Понятно',
        cancel: 'Закрыть'
      },
      notFieldsFilled: {
        title: 'Не заполнены все обязательные поля',
        description: 'Пожалуйста, заполните все обязательные поля',
        ok: 'Понятно'
      },
      emptyTables: 'Нет таблиц коммуникаций',
      buttons: {
        createTable: 'Создать'
      }
    },
    clientInteractionMeta: {
      searchResult: 'Найдено коммуникаций: '
    },
    tableFilters: {
      tableConfigurations: {
        title: 'Конфигурации фильтров'
      },
      salesPipelines: {
        salesPipelinesPlaceholder: 'Воронка коммуникации',
        salePipelineStatusesPlaceholder: 'Этапы воронки коммуникации'
      },
      search: {
        clientPhoneNumberPlaceholder: 'Введите номер телефона',
        placeholder: 'Искать в',
        search: 'Поиск полей',
        result: {
          standartResult: 'Результат поиска:',
          bigResult: '20 или больше',
          emptyResult: 'Результат поиска: Не найдено'
        },
        searchOptions: {
          clientId: 'ID клиента',
          operatorId: 'ID оператора',
          clientPhoneNumber: 'Телефон клиента',
          companyName: 'Название компании',
          clientName: 'Имя клиента',
          email: 'Email'
        }
      },
      buttons: {
        applyFilters: 'Применить фильтры',
        resetFilters: 'Сбросить фильтры',
        saveFilters: 'Сохранить конфигурацию'
      },
      messages: {
        tableSuccessfullyUpdated: 'Конфигурация обновлена',
        updateTableFailed: 'Не удалось обновить конфигурацию'
      },
      shareConfirm: {
        title:
          'Внесенные изменения в настройки этой таблицы будут доступны для всех пользователей, у которых есть доступ к ней',
        description: 'Продолжить?',
        ok: 'Да',
        cancel: 'Нет'
      },
      tableGeneralFilters: {
        title: 'Общая информация',
        form: {
          communicationsTypes: 'Типы коммуникаций',
          communicationsTypesShort: 'Типы',
          directionPlaceholder: 'Направление коммуникации',
          direction: 'Направление: ',
          datesRange: 'Диапазон дат',
          responseTime: 'Время ответа:',
          communicationPartsCount: 'Кол-во сообщений',
          duration: 'Длительность',
          unit: 'Отдел',
          units: 'Отделы:',
          levels: 'Уровни',
          operators: 'Операторы',
          nps: 'NPS',
          reviewStatus: 'Статус проверки',
          status: 'Статус контакта',
          integrations: 'Интеграции'
        }
      },
      tableCategoryName: 'Пользовательские поля и поля из CRM',
      tableCategoryNameCustomFields: 'Пользовательские поля',
      tableReviewsFilters: {
        title: 'Информация по проверкам',
        infoTooltip: 'Данный набор фильтров активен только для оценённых коммуникаций',
        form: {
          reviewsCount: 'Кол-во проверок:',
          checklistsPlaceholder: 'Формы оценки',
          reviewResult: 'Результат проверки:',
          reviewAuthor: 'ФИО проверяющего',
          reviewAuthorShort: 'Проверяющий',
          datesRange: 'Диапазон дат',
          commentsRatingFlags: 'Флаги и комментарии',
          tasksDefinitions: 'Теги',
          questions: 'Критерии',
          questionResult: 'Результат критерия'
        }
      },
      customFieldFilters: {
        title: 'Дополнительная информация'
      }
    },
    conflicts: {
      title: 'Установленные настройки недоступны',
      description1:
        'В связи с изменением настроек профиля вы потеряли доступ к выбранной конфигурации фильтров.',
      description2:
        'Для изменения конфигурации фильтров нажмите “Сбросить фильтры” и задайте новые настройки. Для восстановления доступа к выбранной конфигурации фильтров обратитесь к Админу.'
    },
    exportModal: {
      ok: 'Да',
      cancel: 'Нет',
      title: 'Экспорт списка коммуникаций',
      content: 'После подтверждения запустится процесс экспорта, выберите способ получения файла.',
      contentBreak: 'Вы уверены что хотите прервать процесс экспорта?',
      cancelWait: 'Дождаться',
      cancel2: 'Отмена',
      download: 'Скачать',
      mail: 'Почта'
    },
    importModal: {
      title: 'Импорт звонков',
      content:
        'Импорт успешно добавлен в очередь для обработки. Сообщение об окончании загрузки коммуникаций будет отправлено на вашу электронную почту.',
      errors: {
        title: 'При импорте файла произошла ошибка'
      }
    },
    drawer: {
      title: 'Настройки таблицы',
      columns: {
        title: 'Колонки',
        checklistDefinitionsColumnsTitle: 'Формы оценки',
        customFieldsColumnsTitle: 'Кастомные поля',
        searchColumns: 'Поиск по именам столбцов',
        confirmDeleteColumn: {
          title: 'Вы уверены, что хотите удалить колонку?',
          ok: 'Да',
          cancel: 'Нет'
        },
        buttons: {
          viewAddColumnPanel: 'Добавить колонку',
          hidePanel: 'Скрыть'
        }
      },
      tables: {
        title: 'Конфигурации таблицы',
        empty: 'Не создано ни одной конфигурации',
        tableCountMax: 'Создано максимальное количество таблиц',
        selectTablePlaceholder: 'Выберите активную таблицу',
        shareTable: {
          title: 'Настройки доступа',
          messages: {
            tableSuccessfullyShared: 'Настройки доступа обновлены',
            tableSharedFailed: 'Не удалось обновить настройки доступа'
          },
          sharedUserSelectPlaceholder: 'Введите имя сотрудника',
          buttons: {
            apply: 'Применить'
          }
        },
        strings: {
          copyString: '(Копия)',
          openAsccess: '(доступ открыт)',
          author: 'автор',
          newTableString: 'Новая конфигурация'
        },
        messages: {
          tableSuccessfullyCreated: 'Конфигурация создана',
          tableSuccessfullyCopied: 'Конфигурация скопирована',
          tableSuccessfullyUpdated: 'Конфигурация обновлена',
          tableSuccessfullyDeleted: 'Конфигурация удалена',
          tableSuccessfullyExported: 'Список коммуникаций экспортирован',
          tableSuccessfullyExportMessage1: 'Список коммуникаций придет на почту',
          tableSuccessfullyExportMessage2: 'через несколько минут',
          copyTableFailed: 'Не удалось копировать конфигурацию',
          createTableFailed: 'Не удалось создать конфигурацию',
          updateTableFailed: 'Не удалось обновить конфигурацию',
          deleteTableFailed: 'Не удалось удалить конфигурацию',
          exportTableFailed: 'Не удалось экспортировать список коммуникаций'
        },
        confirmDelete: {
          title: 'Вы уверены что вы хотите удалить данную конфигурацию?',
          description: 'После удаления конфигурации, ее настройки восстановить невозможно',
          ok: 'Удалить',
          cancel: 'Отмена'
        },
        menu: {
          addConfig: 'Добавить конфигурацию',
          editTable: 'Переименовать конфигурацию',
          editTableColumns: 'Редактировать колонки',
          copyTable: 'Создать копию',
          shareTable: 'Поделиться',
          export: 'Экспортировать в Excel',
          exportToTxt: 'Экспортировать в Txt',
          deleteTable: 'Удалить'
        },
        buttons: {
          addConfig: 'Добавить конфигурацию'
        }
      }
    }
  }
};
