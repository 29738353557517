import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Button, Input, message } from 'antd';
import { get, isEmpty, isEqual } from 'lodash';
import { LoadingOutlined, WechatOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Text from 'antd/lib/typography/Text';
import {
  clearThreadEndpoint,
  createThreadEndpoint,
  deleteThreadEndpoint,
  getApiKeyEndpoint,
  getThreadEndpoint,
  updateThreadEndpoint
} from 'core/api';
import { allowedGetSmmrzApiKey } from 'core/config';
import QolioAiIcon from '../../QolioAiIcon';
import { getCurrentUser } from '../../../redux/selectors/users';
import { PERMISSIONS } from '../../../core/utils/constants';

const fadeIn = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.5);
    opacity: 0;
  }
`;

const ModalWrapper = styled.div`
  z-index: 999 !important;
  position: fixed;
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  pointer-events: auto;
  width: 800px;
  height: 450px;
  animation: ${({ isClosing }) => (isClosing ? fadeOut : fadeIn)} 0.3s ease;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  background: white;
  border-bottom: 1px solid #ccc;
  cursor: move;
`;

const ModalContent = styled.div`
  padding: 0 20px 10px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const MessageList = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 8px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 10px;
  background-color: #fafafa;
`;

const InputContainer = styled.div`
  margin-top: auto;
  height: 120px;
`;

const ResizeHandleContainer = styled.div`
  right: -6px;
  bottom: 0;
  width: 44px;
  cursor: se-resize;
  height: 44px;
  position: absolute;
`;

const ResizeHandle = styled.div`
  width: 12px;
  right: 14px;
  border: 2px solid grey;
  height: 12px;
  bottom: 8px;
  position: absolute;
  border-top: 0;
  border-left: 0;
`;

const minWidth = 500;
const minHeight = 500;
const startWidth = 800;
const startHeight = 500;

const CallToGPT = ({ call, reviewId }) => {
  const { t } = useTranslation();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  // Window
  const modalRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [position, setPosition] = useState({ x: '50%', y: '50%' });
  // const [position, setPosition] = useState({ x: 100, y: 100 });
  const [size, setSize] = useState({ width: startWidth, height: startHeight });
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  // Chat
  const messagesEndRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [threadId, setThreadId] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [chatOpening, setChatOpening] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);

  const canSeeChatGpt = useSelector(state =>
    get(getCurrentUser(state), 'role.permissions', []).includes(PERMISSIONS.CAN_SEE_CHATGPT)
  );
  const organizationInfo = useSelector(state => state.organizationInfo.organization, isEqual);
  const canGptDisplayedInOrganization = get(
    organizationInfo,
    'settings.ai.gptDisplayed.enabled',
    false
  );
  const windowInnerHeight = window.innerHeight;

  useEffect(() => {
    setPosition({
      x: (window.innerWidth - startWidth) / 2,
      y: (windowInnerHeight - startHeight) / 3
    });
  }, []);

  // Start window logic

  useEffect(() => {
    const handleMouseMove = e => {
      if (isDragging) {
        const newX = e.clientX - startPos.x;
        const newY = e.clientY - startPos.y;

        // Получаем размеры модального окна
        const modalRect = modalRef.current.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        const viewportHeight = windowInnerHeight;

        // Ограничиваем x так, чтобы модальное окно не выходило за границы экрана
        const constrainedX = Math.max(0, Math.min(newX, viewportWidth - modalRect.width));

        // Ограничиваем y, чтобы модальное окно не выходило вниз
        const constrainedY = Math.max(0, Math.min(newY, viewportHeight - modalRect.height));

        // Устанавливаем новое положение модального окна
        setPosition({
          x: constrainedX,
          y: constrainedY
        });
      }
      if (isResizing) {
        const modalRect = modalRef.current.getBoundingClientRect();

        const deltaX = e.clientX - startPos.x;
        const deltaY = e.clientY - startPos.y;

        const updatedWidth = Math.max(minWidth, size.width + deltaX);
        const updatedHeight = Math.max(minHeight, size.height + deltaY);

        const constrainedWidth = Math.min(updatedWidth, window.innerWidth - modalRect.left);
        const constrainedHeight = Math.min(updatedHeight, windowInnerHeight - modalRect.top);

        setSize({ width: constrainedWidth, height: constrainedHeight });

        const newX =
          constrainedWidth + modalRect.left > window.innerWidth
            ? window.innerWidth - constrainedWidth
            : position.x;
        const newY =
          constrainedHeight + modalRect.top > windowInnerHeight
            ? windowInnerHeight - constrainedHeight
            : position.y;

        setPosition({ x: newX, y: newY });
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      setIsResizing(false);
      document.body.style.userSelect = 'auto';
      document.body.style.cursor = 'auto';
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, isResizing, startPos]);

  const startDragging = e => {
    e.stopPropagation();
    document.body.style.userSelect = 'none';
    const rect = modalRef.current.getBoundingClientRect();
    setStartPos({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    setIsDragging(true);
  };

  const startResizing = e => {
    e.stopPropagation();
    document.body.style.userSelect = 'none';
    document.body.style.cursor = 'se-resize';
    setStartPos({ x: e.clientX, y: e.clientY });
    setIsResizing(true);
  };

  // End window logic

  // Start chat logic

  // auto scroll to bottom when new message
  const scrollToBottom = () => {
    if (messagesEndRef?.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleCloseModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setVisible(false);
      setIsClosing(false);
    }, 300);
    setCurrentMessage('');
    setThreadId(null);
    setApiKey(null);
    setChatOpening(false);
    setApiLoading(false);
  };

  const getApiKey = async () => {
    try {
      const response = await fetch(getApiKeyEndpoint, {
        method: 'POST',
        headers: {
          'X-Delay-Key': allowedGetSmmrzApiKey
        },
        body: JSON.stringify({
          amo_account_id: `qolio_${organizationId}`
        })
      });

      if (!response.ok) {
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }

      const data = await response.json();

      setApiKey(data?.api_key);
      return data?.api_key;
    } catch (error) {
      console.log('error', error);
      message.error(
        'Не удалось получить доступ к чату. Пожалуйста, попробуйте еще раз или свяжитесь с нами.'
      );
      handleCloseModal();
    }
  };

  const getThread = async apiKey => {
    try {
      setApiLoading(true);
      const response = await fetch(
        `${getThreadEndpoint}?interaction_id=${call?.id}&review_id=${reviewId}`,
        {
          method: 'GET',
          headers: {
            'X-Delay-Key': apiKey
          }
        }
      );

      // Smrz doesn't respond
      if (response?.status === 500) {
        handleCloseModal();
        message.error(t('errorPages.internalServerError.title'));
        throw new Error(500);
      }

      // Authorization failed
      if (response?.status === 401) {
        handleCloseModal();
        message.error(t('errorPages.internalServerError.title'));
        throw new Error(401);
      }

      // Response Error
      if (response?.status === 400) {
        handleCloseModal();
        message.error(t('errorPages.internalServerError.title'));
        throw new Error(400);
      }

      // Create new thread
      if (!response.ok) {
        setChatOpening(false);
        throw new Error(404);
      }

      setApiLoading(false);
      return await response.json();
    } catch (error) {
      console.log('error', error);
      setChatOpening(false);
      setApiLoading(false);
      if (error && error?.message === '404') {
        return error;
      }
      return { error, msg: 'response error' };
    }
  };

  const createThread = async apiKey => {
    try {
      const response = await fetch(`${createThreadEndpoint}`, {
        method: 'POST',
        headers: {
          'X-Delay-Key': apiKey
        },
        body: JSON.stringify({
          interaction_id: call?.id,
          review_id: reviewId
        })
      });

      if (!response.ok) {
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }

      const data = await response.json();
      return data?.data;
    } catch (error) {
      console.log('error', error);
      message.error(t('errorPages.internalServerError.title'));
      handleCloseModal();
    }
  };

  const handleOpenChat = async () => {
    setChatOpening(true);
    const apiKey = await getApiKey();
    if (!apiKey) return;
    const thread = await getThread(apiKey);
    if (thread && thread.msg) return;
    // Если не удалось получить существующий чат
    if (isEmpty(thread)) {
      const newTread = await createThread(apiKey); // Создаём новый чат
      if (newTread) {
        setThreadId(newTread); // Записываем чат ID
        setVisible(true); // Отображаем чат
        setChatOpening(false);
      }
    }
    // Если есть существующий чат
    if (!isEmpty(thread)) {
      setThreadId(thread?.meta?.thread_id); // Записываем чат ID
      setVisible(true); // Отображаем чат
      // Если если есть сообщения в чате
      if (!isEmpty(thread?.data)) {
        setMessages(thread?.data); // Загружаем чат
        setChatOpening(false);
      }
    }
  };

  // Отправка сообщения
  const updateThread = async (text, newMessages) => {
    try {
      // Добавляем заглушку в виде спиннера до ответа GPT
      newMessages.push({
        role: 'assistant',
        message: null
      });
      setMessages(newMessages); // Обновляем чат
      const response = await fetch(`${updateThreadEndpoint}`, {
        method: 'POST',
        headers: {
          'X-Delay-Key': apiKey
        },
        body: JSON.stringify({
          id: threadId,
          messages: [
            {
              text
            }
          ]
        })
      });

      if (!response.ok) {
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      message.error(t('errorPages.internalServerError.title'));
      console.log('error', error);
    }
  };

  const handleSendMessage = async (text = '') => {
    setApiLoading(true);
    const userMessage = text || currentMessage;
    if (typeof userMessage === 'string' && userMessage.trim()) {
      // Добавляем сообщение пользователя в чат
      const newMessages = [...messages, { role: 'user', message: userMessage }];
      const threadMsgs = await updateThread(userMessage, newMessages); // Отправляем написанное сообщение GPT
      setMessages(threadMsgs?.data || messages); // Обновляем чат
      setApiLoading(false);
      if (isEmpty(threadMsgs)) return;
      setCurrentMessage('');
    }
  };

  // Обработчик для установки текста из выпадающего списка
  // const handleAutoFill = text => {
  //   if (apiLoading) return;
  //   setCurrentMessage(text); // Устанавливаем текст в поле ввода
  //   setTimeout(() => {
  //     handleSendMessage(text); // Отправляем сообщение
  //   }, 0);
  // };

  const handleClearThread = async () => {
    try {
      setApiLoading(true);
      const response = await fetch(`${clearThreadEndpoint}?id=${threadId}`, {
        method: 'DELETE',
        headers: {
          'X-Delay-Key': apiKey
        }
      });

      if (!response.ok) {
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }
      setMessages([]);
      setApiLoading(false);
      message.success('Чат очищен');
    } catch (error) {
      setApiLoading(false);
      message.error('Не удалось очистить чат, пожалуйста попробуйте повторить попытку');
      console.log('error', error);
    }
  };

  // const handleDeleteThread = async () => {
  //   try {
  //     setApiLoading(true);
  //     const response = await fetch(`${deleteThreadEndpoint}?id=${threadId}`, {
  //       method: 'DELETE',
  //       headers: {
  //         'X-Delay-Key': apiKey
  //       }
  //     });
  //
  //     if (!response.ok) {
  //       throw new Error(`Ошибка HTTP: ${response.status}`);
  //     }
  //     setMessages([]);
  //     handleCloseModal();
  //     message.success('Чат удалён');
  //   } catch (error) {
  //     setApiLoading(false);
  //     message.error('Не удалось удалить чат, пожалуйста попробуйте повторить попытку');
  //     console.log('error', error);
  //   }
  // };

  // End chat logic

  return (
    <>
      {visible && (
        <ModalWrapper
          isClosing={isClosing}
          style={{
            left: position.x,
            top: position.y,
            width: size.width,
            height: size.height
          }}
          ref={modalRef}
        >
          <ModalContent>
            <ModalHeader onMouseDown={startDragging}>
              <div style={{ fontWeight: 500, fontSize: '22px' }}>
                <Text>Обращение к Qolio</Text>
                <QolioAiIcon styles={{ marginLeft: '5px' }} alwaysShow />
              </div>
              <div
                onClick={() => handleCloseModal()}
                style={{ fontSize: '25px', cursor: 'pointer' }}
              >
                ×
              </div>
            </ModalHeader>
            {/* Блок с сообщениями */}
            <MessageList>
              {isEmpty(messages) && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                  <WechatOutlined style={{ color: '#1877F2', fontSize: '200px' }} />
                  <div style={{ fontSize: '22px' }}>Начните новый чат</div>
                </div>
              )}
              {!isEmpty(messages) &&
                messages.map(item => {
                  const role = item?.role;
                  const createdAt = item?.created_at;
                  const message = item?.message;
                  return (
                    <div
                      key={createdAt}
                      style={{
                        display: 'flex',
                        justifyContent: role === 'user' ? 'flex-end' : 'flex-start',
                        marginBottom: '8px'
                      }}
                    >
                      <div
                        style={{
                          maxWidth: apiLoading ? '100vh' : '75vh',
                          padding: '8px 12px',
                          borderRadius: '12px',
                          background: role === 'user' ? '#e6f7ff' : '#f5f5f5',
                          border: role === 'user' ? '1px solid #91d5ff' : '1px solid #d9d9d9'
                        }}
                      >
                        {message === null ? <LoadingOutlined /> : <>{message}</>}
                      </div>
                    </div>
                  );
                })}
              <div ref={messagesEndRef} />
              {/* Блок с сообщениями для автоввода */}
              {/*<div*/}
              {/*  style={{*/}
              {/*    display: 'flex',*/}
              {/*    alignItems: 'center',*/}
              {/*    gap: '10px',*/}
              {/*    marginTop: '20px',*/}
              {/*    justifyContent: 'center',*/}
              {/*    flexWrap: 'wrap'*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {autoCompleteOptions.map((option, index) => (*/}
              {/*    <div*/}
              {/*      style={{*/}
              {/*        padding: '6px',*/}
              {/*        backgroundColor: '#1877F2',*/}
              {/*        color: '#fff',*/}
              {/*        borderRadius: '6px',*/}
              {/*        cursor: 'pointer'*/}
              {/*      }}*/}
              {/*      key={index}*/}
              {/*      onClick={() => handleAutoFill(option)}*/}
              {/*    >*/}
              {/*      {option}*/}
              {/*    </div>*/}
              {/*  ))}*/}
              {/*</div>*/}
            </MessageList>
            {/* поле ввода сообщения */}
            <InputContainer>
              <Input.TextArea
                value={currentMessage}
                onChange={e => setCurrentMessage(e.target.value)}
                placeholder="Введите сообщение"
                rows={2}
                style={{
                  marginBottom: '8px',
                  height: '70px',
                  resize: 'none'
                }}
              />
              {/* кнопка отправки */}
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <div>
                  <Button
                    type="primary"
                    disabled={!currentMessage.trim() || apiLoading}
                    onClick={() => handleSendMessage()}
                    loading={apiLoading}
                  >
                    Отправить
                  </Button>
                  <Button
                    type="default"
                    loading={apiLoading}
                    onClick={() => handleClearThread()}
                    style={{ marginLeft: '10px' }}
                  >
                    Очистить чат
                  </Button>
                </div>
                {/*<div>*/}
                {/*  <Button type="danger" loading={apiLoading} onClick={() => handleDeleteThread()}>*/}
                {/*    Удалить чат*/}
                {/*  </Button>*/}
                {/*</div>*/}
              </div>
            </InputContainer>
            <ResizeHandleContainer onMouseDown={startResizing}>
              <ResizeHandle />
            </ResizeHandleContainer>
          </ModalContent>
        </ModalWrapper>
      )}
      {canGptDisplayedInOrganization && canSeeChatGpt && (
        <Button type="default" onClick={handleOpenChat} disabled={visible} loading={chatOpening}>
          Обращение к Qolio <QolioAiIcon styles={{ marginLeft: '5px' }} alwaysShow />
        </Button>
      )}
    </>
  );
};

export default CallToGPT;
