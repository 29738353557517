import React from 'react';
import { Col, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { SRow, SText } from '../Standard';
import { getCustomFieldByKey, isCustomFieldVisible } from '../../core/utils/isCustomFieldVisible';
import { Info } from '../Info';
import CustomFieldValueView from '../CustomFieldValueView';
import QolioAiIcon from '../QolioAiIcon';

const CustomFieldsView = ({ communicationChainCustomFields, customFieldsByKeys }) => {
  const { t } = useTranslation();
  return (
    <SRow>
      {Object.keys(communicationChainCustomFields).map(key => {
        const customField = getCustomFieldByKey(customFieldsByKeys, key);
        if (!isCustomFieldVisible(customField)) {
          return '';
        }

        return (
          <Col key={key} span={24}>
            {!customField && (
              <Info
                tooltip={{
                  title: t('components.communicationInfoModal.emptyFieldDescription')
                }}
                iconProps={{
                  style: { color: 'var(--red_6)', marginRight: 4 }
                }}
              />
            )}
            {customField?.usedForAi && (
              <QolioAiIcon styles={{ marginRight: '5px', paddingBottom: '2px' }} />
            )}
            <SText strong>
              <Tooltip title={customField?.name}>{customField?.name}</Tooltip>
            </SText>
            {`: `}
            <CustomFieldValueView
              customFields={communicationChainCustomFields}
              customField={customField}
              sliced={false}
            />
          </Col>
        );
      })}
    </SRow>
  );
};

export default CustomFieldsView;
