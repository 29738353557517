import React from 'react';
import { Collapse, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';
import ColumnTitle from '../../../../components/ColumnTitle';
import QuestionColumn from '../../../../components/QuestionColumn';

const { Panel } = Collapse;

const ChecklistDefinitionsColumns = ({
  checklistDefinitionsIds,
  customFieldsByKeys,
  checklistDefinitionsColumns,
  questionsChecklist,
  addColumn,
  tableColumns
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Row type="flex" align="middle" justify="space-between" style={{ height: '40px' }}>
        <Text strong>
          {t('clientInteractionsPage.drawer.columns.checklistDefinitionsColumnsTitle')}
        </Text>
      </Row>
      <div style={{ overflowY: 'auto', maxHeight: '600px' }}>
        {checklistDefinitionsColumns.map(column => {
          return (
            <Collapse
              defaultActiveKey={['1']}
              style={{ width: '100%', margin: '4px 0' }}
              expandIconPosition="right"
            >
              <Panel
                key={column}
                header={
                  <ColumnTitle
                    columnType={column}
                    customFieldsByKeys={customFieldsByKeys}
                    checklistDefinitionsIds={checklistDefinitionsIds}
                    questionsChecklist={questionsChecklist}
                  />
                }
              >
                {checklistDefinitionsIds[column] && (
                  <QuestionColumn
                    checklistDefinition={checklistDefinitionsIds[column]}
                    addColumn={addColumn}
                    tableColumns={tableColumns}
                  />
                )}
              </Panel>
            </Collapse>
          );
        })}
      </div>
    </div>
  );
};

export default ChecklistDefinitionsColumns;
