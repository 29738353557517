import {
  Alert,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Tooltip,
  Typography
} from 'antd';
import Text from 'antd/lib/typography/Text';
import ColorZones from 'components/ColorZones/ColorZones';
import ColorZonesForCustomScale from 'components/ColorZones/ColorZonesForCustomScale';
import LabelWithInfo from 'components/LabelWithInfo';
import RateValues from 'components/Inputs/RateValues';
import colorZonesFromRatingValues from 'core/utils/colorZonesFromRatingValues';
import {
  BOOL_VALUES,
  CHECKLIST_DEFINITION_STATUS,
  RATING_MODE,
  SCALE_TYPES
} from 'core/utils/constants';
import { debounce, every, get, isEmpty, isEqual, isNil, max, min, some, uniq } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getQuestionChecklistDefinitions } from 'redux/selectors/checklistItems/checklistItems';
import {
  setEditingQuestionWithBinding,
  setPromptLibrary,
  setPromptRadio,
  setUseAi,
  updateEditingQuestion,
  updateEditingQuestionToGroupBinding
} from 'redux/ui/questionModal/reducer';
import styled from 'styled-components';
import uniqid from 'uniqid';
import SModal from 'components/Standard/SModal';
import { CustomRatingValuesEditor } from './components/CustomRatingValuesEditor';
import StandardComments from './components/StandardComments';
import QolioAiIcon from '../../QolioAiIcon';

const { Option } = Select;
const { TextArea } = Input;

const RateInput = styled(InputNumber)`
  width: 63px !important;
  margin: 0 5px !important;

  .ant-input-number-handler-wrap {
    opacity: 1 !important;
  }
`;

const SText = styled.div`
  font-weight: 700;
`;

const labelCol = { md: { offset: 0, span: 10 } };
const inputCol = { md: { span: 14 } };
const defaultQuestion = {};
const defaultCurrentChecklist = {};

const defaultState = {
  question: defaultQuestion,
  questionToGroupBinding: {},
  promptInfo: {
    selectedPrompt: {},
    useAi: false,
    promptRadio: '',
    promptLibrary: null
  }
};

const isQuestionConnected = (state, question) =>
  some(
    getQuestionChecklistDefinitions(state, question),
    checklist => checklist?.status === CHECKLIST_DEFINITION_STATUS.PUBLISHED.value
  );

const QuestionModal = ({
  visible,
  currentChecklist = defaultCurrentChecklist,
  onSubmit,
  onCancel
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organizationInfo = useSelector(state => state.organizationInfo.organization, isEqual);
  const canAiAnalysisClientInteractionsInOrganization = get(
    organizationInfo,
    'settings.ai.analysis.enabled',
    false
  );
  const promptsResource = useSelector(state => state.promptsResource.byIds);
  const prompts = Object.values(promptsResource);
  // Свободные промпты - промпты которые не привязаны к другим критериям
  const freePrompts = prompts.filter(
    item => item.availability === 'free' && item.bindingType === 'question'
  );

  const { question = defaultQuestion, questionToGroupBinding, promptInfo } = useSelector(
    state => state.uiQuestionModal,
    shallowEqual
  );

  const [promptsAPIloading, setPromptsAPILoading] = useState(false);

  const { createStarted, loadByIdStarted, updateByIdStarted, byIds } = useSelector(
    state => state.questionsResource,
    shallowEqual
  );

  const {
    createStarted: bindingCreateStarted,
    updateByIdStarted: bindingUpdateStarted
  } = useSelector(state => state.questionToGroupBindingsResource, shallowEqual);

  const isNewQuestion = isEmpty(get(byIds, question.id, false));

  const loading =
    createStarted ||
    loadByIdStarted ||
    updateByIdStarted ||
    bindingCreateStarted ||
    bindingUpdateStarted ||
    promptsAPIloading;

  const connectedToChecklist = !isEmpty(currentChecklist) && !isEmpty(questionToGroupBinding);

  const questionConnectedToPublishedChecklist = useSelector(state =>
    isQuestionConnected(state, question)
  );

  // * some values can be pushed from checklist definition
  const { scaleType = SCALE_TYPES.max_5.type, valuesDisplayType = 'numbers' } = connectedToChecklist
    ? currentChecklist
    : question;

  // * some values can be pushed from binding
  const {
    checklistAutofailEnabled: bindingAutofailEnabled,
    checklistAutofailValues = [],
    checklistAutofailResultPercentage = 0,
    groupAutofailEnabled: bindingGroupAutofailEnabled,
    groupAutofailValues = [],
    groupAutofailResultPercentage = 0,
    defaultRatingValueEnabled = false,
    defaultRatingValueScore = null,
    questionGroupId
  } = questionToGroupBinding;

  const questionGroup = useSelector(
    state => state.questionGroupsResource.byIds[questionGroupId],
    isEqual
  );

  const {
    name = '',
    text = '',
    standardCommentsEnabled = false,
    standardComments = {},
    colorZones = SCALE_TYPES[scaleType].colorZones,
    ratingValues = SCALE_TYPES[scaleType].numbers || [0, 1],
    ratingValuesViews = ['', '']
  } = question;

  const { selectedPrompt = {}, useAi = false, promptRadio = '', promptLibrary = null } = promptInfo;

  const maxRating = SCALE_TYPES[scaleType].max || max(ratingValues);
  const isCustomBinary = scaleType === SCALE_TYPES.custom_binary.type;
  const isCustom =
    scaleType === SCALE_TYPES.custom.type || scaleType === SCALE_TYPES.custom_with_views.type;

  const ColorZonesComponent = isCustom ? ColorZonesForCustomScale : ColorZones;

  const minRating =
    isNil(min(SCALE_TYPES[scaleType].numbers)) || isCustomBinary
      ? min(ratingValues)
      : min(SCALE_TYPES[scaleType].numbers);

  const updateQuestionOrBinding = data =>
    dispatch(
      connectedToChecklist ? updateEditingQuestionToGroupBinding(data) : updateEditingQuestion(data)
    );

  const changeRates = async (value, index, rates = null) => {
    const newRates = rates || [...ratingValues];

    if (!rates) {
      newRates[index] = value;
    }

    dispatch(
      updateEditingQuestion({
        ratingValues: newRates,
        ...(isCustomBinary
          ? {
              colorZones: {
                ...colorZones,
                medium: {
                  ...colorZones.medium,
                  stop: max(ratingValues)
                },
                low: { ...colorZones.low, stop: min(ratingValues) }
              }
            }
          : {}),
        ...(isCustom ? { colorZones: colorZonesFromRatingValues(newRates, colorZones) } : {}),
        ...(newRates.length === 1
          ? {
              colorZones: {
                ...colorZones,
                medium: { text: colorZones.medium.text },
                low: { ...colorZones.low, stop: newRates[0] },
                high: { text: colorZones.high.text }
              }
            }
          : {})
      })
    );
    dispatch(
      updateEditingQuestionToGroupBinding({
        checklistAutofailEnabled: false,
        checklistAutofailValues: [],
        groupAutofailEnabled: false,
        groupAutofailValues: [],
        defaultRatingValueScore: null,
        defaultRatingValueEnabled: false
      })
    );
  };

  const scaleTypeToRatingValues = question => ({
    ...question,
    ratingValues:
      isCustomBinary || isCustom
        ? question.ratingValues
        : SCALE_TYPES[question.scaleType].numbers || question.ratingValues
  });

  const checklistAutofailEnabled = !!bindingAutofailEnabled;
  const groupAutofailEnabled = !!bindingGroupAutofailEnabled;

  const ratingValuesAreValid =
    uniq(ratingValues).length === ratingValues.length &&
    ratingValues.length >= 1 &&
    ratingValues.length <= 10;

  const ratingValuesViewsAreValid =
    uniq(ratingValuesViews).length === ratingValuesViews.length &&
    !some(ratingValuesViews, isEmpty);

  const standardCommentsAreValid =
    !standardCommentsEnabled ||
    (standardCommentsEnabled &&
      every(Object.values(standardComments), comment =>
        every(Object.values(comment), value => !isEmpty(value))
      ));

  const disabledSave =
    (SCALE_TYPES.custom_with_views.type === scaleType &&
      (!ratingValuesAreValid || !ratingValuesViewsAreValid)) ||
    (SCALE_TYPES.custom.type === scaleType && !ratingValuesAreValid) ||
    !standardCommentsAreValid;

  const onValuesChange = debounce(async (changedValues, allValues) => {
    const {
      checklistAutofailEnabled,
      groupAutofailEnabled,
      defaultRatingValueEnabled,
      ...newQuestion
    } = allValues;

    const newSelectedPromptId = changedValues.selectedPromptId;
    const newPromptLibrary = changedValues.promptLibrary;
    const newSelectedPromptValue = changedValues.selectedPromptValue;
    const newScaleType = changedValues.scaleType;

    // Если было изменено значение промпта
    if (newSelectedPromptValue) {
      form.setFieldsValue({ selectedPromptValue: newSelectedPromptValue });

      return dispatch(
        updateEditingQuestion({
          ...newQuestion,
          selectedPromptId: promptLibrary,
          selectedPromptValue: newSelectedPromptValue
        })
      );
    }

    // Если был выбран промпт из библиотеки
    if (newPromptLibrary) {
      const newPrompt = freePrompts.find(prompt => prompt?.id === newPromptLibrary);
      if (selectedPrompt?.id === newPromptLibrary) {
        form.setFieldsValue({ selectedPromptValue: selectedPrompt?.value || '' });
      } else {
        form.setFieldsValue({ selectedPromptValue: newPrompt?.value || '' });
      }
      return dispatch(
        updateEditingQuestion({
          ...newQuestion,
          selectedPromptId: newPrompt?.id,
          selectedPromptValue: newPrompt?.value
        })
      );
    }

    // Если был выбран другой промпт
    if (newSelectedPromptId) {
      const newPrompt =
        selectedPrompt?.id === newSelectedPromptId
          ? selectedPrompt
          : freePrompts.find(prompt => prompt?.id === newSelectedPromptId);
      form.setFieldsValue({ selectedPromptValue: newPrompt?.value || '' });
      return dispatch(
        updateEditingQuestion({
          ...newQuestion,
          selectedPromptValue: newPrompt?.value
        })
      );
    }

    if (newScaleType) {
      const { colorZones } = question;

      const typeToNewZone = {
        [SCALE_TYPES.custom_binary.type]: {
          ...colorZones,
          medium: {
            ...colorZones.medium,
            stop: get(allValues, ['ratingValues', 1], 1)
          },
          low: { ...colorZones.low, stop: get(allValues, ['ratingValues', 0], 0) }
        },
        [SCALE_TYPES.custom]: {
          ...colorZones,
          medium: { ...colorZones.medium, stop: get(allValues, ['ratingValues', 1]) },
          low: { ...colorZones.low, stop: get(allValues, ['ratingValues', 0]) }
        },
        [SCALE_TYPES.custom_with_views]: {
          ...colorZones,
          medium: { ...colorZones.medium, stop: get(allValues, ['ratingValues', 1]) },
          low: { ...colorZones.low, stop: get(allValues, ['ratingValues', 0]) }
        },
        default: {
          ...colorZones,
          medium: {
            ...colorZones.medium,
            stop: get(SCALE_TYPES, [newScaleType, 'colorZones', 'medium', 'stop'])
          },
          low: { ...colorZones.low, stop: SCALE_TYPES[newScaleType].colorZones.low.stop }
        }
      };

      const newColorZones = typeToNewZone[newScaleType] || typeToNewZone.default;

      return dispatch(
        updateEditingQuestion({
          ...newQuestion,
          colorZones: newColorZones,
          ratingValues: SCALE_TYPES[newScaleType].numbers || allValues.ratingValues || [0, 1]
        })
      );
    }

    if (connectedToChecklist) {
      dispatch(updateEditingQuestion(newQuestion));
      return dispatch(
        updateEditingQuestionToGroupBinding({
          checklistAutofailEnabled,
          groupAutofailEnabled,
          defaultRatingValueEnabled
        })
      );
    }

    return dispatch(updateEditingQuestion(newQuestion));
  }, 250);

  const initialValues = {
    name,
    text,
    scaleType,
    valuesDisplayType,
    defaultRatingValueEnabled,
    checklistAutofailEnabled,
    groupAutofailEnabled,
    standardCommentsEnabled,
    useAi,
    promptRadio,
    promptLibrary,
    selectedPromptId: selectedPrompt ? selectedPrompt?.id : null,
    selectedPromptValue: selectedPrompt ? selectedPrompt?.value : null,
    checklistAutofailValues,
    groupAutofailValues
  };

  useEffect(() => {
    if (question?.id) {
      form.resetFields();
      form.setFieldsValue(initialValues);
    }
  }, [question?.id, checklistAutofailEnabled, groupAutofailEnabled, defaultRatingValueEnabled]);

  const closeModal = useCallback(() => {
    dispatch(setEditingQuestionWithBinding(defaultState));
    if (onCancel) {
      onCancel();
    }
  }, [dispatch]);

  const handleSubmit = () => {
    setPromptsAPILoading(true);
    return onSubmit({
      question: scaleTypeToRatingValues(question),
      questionToGroupBinding,
      setPromptsAPILoading,
      selectedPrompt,
      promptInfo
    });
  };

  // Если критерий привязан к формам оценки которые уже опубликованны или в архиве то запрещаем редактирование многих полей
  const getDisabled = (isPrompts = false) => {
    if (!question?.definitions) return; // все ФО к которым привязан критерий и их статус

    // Промпты можно редактировать в любом статусе кроме архива
    if (isPrompts) {
      const isConnected = Object.values(question?.definitions).some(
        item => item?.status === 'archived'
      );
      return !!isConnected;
    }
    const isConnected = Object.values(question?.definitions).some(
      item => item?.status === 'archived' || item?.status === 'published'
    );
    return !!isConnected;
  };

  return (
    <SModal
      size="big"
      destroyOnClose
      maskClosable
      visible={visible || !isEmpty(question)}
      title={t('components.questionModal.title')}
      width={900}
      onCancel={closeModal}
      onOk={form.submit}
      confirmLoading={loading}
      cancelText={<></>}
      okText={isNewQuestion ? t('general.save') : t('general.update')}
      okButtonProps={{ disabled: disabledSave }}
    >
      <Form
        labelCol={labelCol}
        labelAlign="left"
        wrapperCol={inputCol}
        onFinish={handleSubmit}
        form={form}
        colon={false}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        scrollToFirstError
      >
        {/* Название title */}
        <Form.Item
          label={
            <LabelWithInfo infoTitle={t('components.questionModal.form.fields.nameInfo')}>
              {t('components.questionModal.form.fields.name')}
            </LabelWithInfo>
          }
          name="name"
          rules={[
            {
              required: true,
              message: t('components.questionModal.form.messages.enterName'),
              whitespace: true
            }
          ]}
        >
          {/* Название критерия input */}
          <Input placeholder={t('components.questionModal.form.fields.namePlaceholder')} />
        </Form.Item>
        {/* Описание title */}
        <Form.Item
          label={
            <LabelWithInfo infoTitle={t('components.questionModal.form.fields.descriptionInfo')}>
              {t('components.questionModal.form.fields.description')}
            </LabelWithInfo>
          }
          name="text"
        >
          {/* Описание критерия input */}
          <TextArea
            placeholder={t('components.questionModal.form.fields.descriptionPlaceholder')}
          />
        </Form.Item>
        {/* Использование AI title */}
        <Form.Item
          label={
            <LabelWithInfo
              infoTitle={t('components.promptsList.form.fields.prompts.placeholders.useAi')}
            >
              {t('components.promptsList.form.fields.prompts.useAi')}
              <QolioAiIcon styles={{ marginLeft: '5px', paddingBottom: '2px' }} alwaysShow />
            </LabelWithInfo>
          }
          name="useAi"
        >
          {/* Выбор Да/Нет select */}
          <Radio.Group
            disabled={getDisabled(true) || !canAiAnalysisClientInteractionsInOrganization}
            style={{ width: '100%' }}
            onChange={e => dispatch(setUseAi(e.target.value))}
          >
            <Row type="flex">
              <Col span={8}>
                <Radio value={false}>{t(BOOL_VALUES.FALSE.title)}</Radio>
              </Col>
              <Col span={8}>
                <Radio value>{t(BOOL_VALUES.TRUE.title)}</Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
        {useAi && (
          <>
            {/* Добавить промпт */}
            <Form.Item
              label={
                <LabelWithInfo
                  infoTitle={t('components.promptsList.form.fields.prompts.selectOrCreatePrompt')}
                >
                  {t('components.promptsList.form.fields.prompts.addPrompt')}
                </LabelWithInfo>
              }
              name="promptRadio"
              rules={[
                {
                  required: true,
                  message: t('components.promptsList.form.fields.prompts.messages.selectAny')
                }
              ]}
            >
              {/* Выберите промпт из библиотеки или создайте новый */}
              <Radio.Group
                disabled={getDisabled(true) || !canAiAnalysisClientInteractionsInOrganization}
                style={{ width: '100%' }}
                onChange={e => dispatch(setPromptRadio(e.target.value))}
              >
                <Row type="flex">
                  <Col span={8} style={{ marginRight: '30px' }}>
                    {/* Промпт из библиотеки */}
                    <Radio value="selectPrompt">
                      {t('components.promptsList.form.fields.prompts.promptFromLibrary')}
                    </Radio>
                  </Col>
                  <Col span={8}>
                    {/* Новый промпт */}
                    <Radio value="createPrompt">
                      {t('components.promptsList.form.fields.prompts.newPrompt')}
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
            {/* Если выбрано промпт из библиотеки */}
            {promptRadio === 'selectPrompt' && (
              <>
                {/* Промпт из библиотеки title */}
                <Form.Item
                  label={
                    <LabelWithInfo
                      infoTitle={t(
                        'components.promptsList.form.fields.prompts.placeholders.selectOrCreatePrompt'
                      )}
                    >
                      {t('components.promptsList.form.fields.prompts.promptFromLibrary')}
                    </LabelWithInfo>
                  }
                  name="promptLibrary"
                  rules={[
                    {
                      required: true,
                      message: t('components.promptsList.form.fields.prompts.selectPrompt')
                    }
                  ]}
                >
                  {/* Выбор промптов из библиотеки */}
                  <Select
                    disabled={getDisabled(true) || !canAiAnalysisClientInteractionsInOrganization}
                    defaultValue={selectedPrompt.id}
                    onChange={promptId => dispatch(setPromptLibrary(promptId))}
                    placeholder={t('components.promptsList.form.fields.prompts.selectPrompt')}
                  >
                    {/* Выводим привязанный промпт отдельно так как его нет в списке свободных промптов - freePrompts */}
                    {!isEmpty(selectedPrompt) && (
                      <Option value={selectedPrompt.id} key={selectedPrompt.id}>
                        {selectedPrompt.name}
                      </Option>
                    )}
                    {/* Выводим список свободных промптов не привязаных к критерию */}
                    {freePrompts.map(prompt => (
                      <Option value={prompt?.id} key={prompt?.id}>
                        {prompt?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {/* Значение title */}
                <Form.Item
                  label={
                    <LabelWithInfo
                      infoTitle={t(
                        'components.promptsList.form.fields.prompts.placeholders.queryForAnalyze'
                      )}
                    >
                      {t('components.promptsList.form.fields.prompts.value')}
                    </LabelWithInfo>
                  }
                  name="selectedPromptValue"
                  rules={[
                    {
                      required: true,
                      message: t('components.promptsList.form.fields.prompts.messages.fillField'),
                      whitespace: true
                    }
                  ]}
                >
                  {/* Значение промпта */}
                  <TextArea
                    disabled={getDisabled(true) || !canAiAnalysisClientInteractionsInOrganization}
                    placeholder={t(
                      'components.promptsList.form.fields.prompts.placeholders.promptValue'
                    )}
                  />
                </Form.Item>
              </>
            )}
            {/* Если выбрано Новый промпт */}
            {promptRadio === 'createPrompt' && (
              <>
                {/* Название промпта title */}
                <Form.Item
                  label={
                    <Tooltip title={t('components.promptsList.form.fields.prompts.promptName')}>
                      <SText>{t('components.promptsList.form.fields.prompts.promptName')}</SText>
                    </Tooltip>
                  }
                  name="promptName"
                  rules={[
                    {
                      message: t('components.promptsList.form.fields.prompts.messages.fillField'),
                      whitespace: true
                    }
                  ]}
                >
                  {/* Название промпта input */}
                  <Input
                    disabled={getDisabled(true) || !canAiAnalysisClientInteractionsInOrganization}
                    placeholder={t('components.promptsList.form.fields.prompts.promptName')}
                    defaultValue={name}
                  />
                </Form.Item>
                {/* Значение промпта title */}
                <Form.Item
                  label={
                    <LabelWithInfo
                      infoTitle={t(
                        'components.promptsList.form.fields.prompts.placeholders.queryForAnalyze'
                      )}
                    >
                      {t('components.promptsList.form.fields.prompts.promptValue')}
                    </LabelWithInfo>
                  }
                  name="promptValue"
                  rules={[
                    {
                      required: true,
                      message: t('components.promptsList.form.fields.prompts.messages.fillField'),
                      whitespace: true
                    }
                  ]}
                >
                  {/* Значение промпта input */}
                  <TextArea
                    disabled={getDisabled(true) || !canAiAnalysisClientInteractionsInOrganization}
                    placeholder={t('components.promptsList.form.fields.prompts.promptValue')}
                  />
                </Form.Item>
              </>
            )}
          </>
        )}
        <Form.Item
          label={
            <LabelWithInfo infoTitle={t('components.questionModal.form.fields.scaleTypeInfo')}>
              {t('components.questionModal.form.fields.scaleType')}
            </LabelWithInfo>
          }
          name="scaleType"
        >
          <Select
            disabled={
              isNewQuestion
                ? connectedToChecklist || questionConnectedToPublishedChecklist
                : getDisabled()
            }
          >
            {Object.values(SCALE_TYPES).map(({ type, name }) => (
              <Option value={type} key={type}>
                {t(name)}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {!isCustom ? (
          <Form.Item
            label={
              <LabelWithInfo
                infoTitle={t('components.questionModal.form.fields.valuesDisplayTypeInfo')}
              >
                {t('components.questionModal.form.fields.valuesDisplayType')}
              </LabelWithInfo>
            }
            name="valuesDisplayType"
          >
            <Select
              disabled={
                isNewQuestion
                  ? connectedToChecklist || questionConnectedToPublishedChecklist
                  : getDisabled()
              }
            >
              {Object.values(SCALE_TYPES[scaleType].valuesOptions).map(({ type, text }) => (
                <Option value={type} key={type}>
                  {t(text)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <Row className="ant-form-item">
            <Col span={10}>
              <Typography.Text strong>
                {t('components.questionModal.form.questionAnswers')}
              </Typography.Text>
            </Col>
            <Col span={14}>
              <CustomRatingValuesEditor
                colorZones={colorZones}
                scaleType={scaleType}
                changeRates={changeRates}
                ratingValues={ratingValues}
                ratingValuesViews={ratingValuesViews}
                disabled={isNewQuestion ? questionConnectedToPublishedChecklist : getDisabled()}
              />
            </Col>
          </Row>
        )}

        {scaleType === SCALE_TYPES.custom_binary.type && (
          <Row
            type="flex"
            className="ant-form-item"
            gutter={[8, 8]}
            style={{ marginBottom: '-4px -4px 24px -4px' }}
          >
            <Col>
              <Row type="flex" align="middle" gutter={[8, 8]} style={{ margin: '-4px' }}>
                <Col>
                  <Typography.Text strong>
                    {valuesDisplayType !== 'numbers' &&
                      t(SCALE_TYPES.custom_binary[valuesDisplayType][0])}
                  </Typography.Text>
                </Col>
                <Col>
                  <RateInput
                    key={uniqid()}
                    step={1}
                    max={100}
                    value={ratingValues[0]}
                    onChange={value => changeRates(value, 0)}
                    disabled={isNewQuestion ? questionConnectedToPublishedChecklist : getDisabled()}
                  />
                </Col>
              </Row>
            </Col>

            <Col>
              <Row type="flex" align="middle" gutter={[8, 8]} style={{ margin: '-4px' }}>
                <Col>
                  <Typography.Text strong>
                    {valuesDisplayType !== 'numbers' &&
                      t(SCALE_TYPES.custom_binary[valuesDisplayType][1])}
                  </Typography.Text>
                </Col>
                <Col>
                  <RateInput
                    key={uniqid()}
                    step={1}
                    max={100}
                    value={ratingValues[1]}
                    onChange={value => changeRates(value, 1)}
                    disabled={isNewQuestion ? questionConnectedToPublishedChecklist : getDisabled()}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {connectedToChecklist && (
          <Form.Item
            label={
              <LabelWithInfo
                infoTitle={t('components.questionModal.form.fields.defaultRatingValueEnabledInfo')}
              >
                {t('components.questionModal.form.fields.defaultRatingValueEnabled')}
              </LabelWithInfo>
            }
            name="defaultRatingValueEnabled"
          >
            <Radio.Group
              style={{ width: '100%' }}
              onChange={() => updateQuestionOrBinding({ defaultRatingValueScore: null })}
            >
              <Row type="flex">
                <Col span={8}>
                  <Radio value={false}>{t(BOOL_VALUES.FALSE.title)}</Radio>
                </Col>
                <Col span={8}>
                  <Radio value>{t(BOOL_VALUES.TRUE.title)}</Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>
        )}

        {defaultRatingValueEnabled && (
          <Row className="ant-form-item">
            <Col md={{ span: 14, offset: 10 }}>
              <RateValues
                size="medium"
                ratingValues={ratingValues}
                ratingValuesViews={ratingValuesViews}
                scaleType={scaleType}
                valuesDisplayType={valuesDisplayType}
                value={defaultRatingValueScore}
                onChange={defaultRatingValueScore =>
                  updateQuestionOrBinding({ defaultRatingValueScore })
                }
              />
            </Col>
          </Row>
        )}

        {connectedToChecklist && (
          <Form.Item
            label={
              <LabelWithInfo
                infoTitle={t('components.questionModal.form.fields.autofailEnabledInfo')}
              >
                {t('components.questionModal.form.fields.autofailEnabled')}
              </LabelWithInfo>
            }
            name="checklistAutofailEnabled"
          >
            <Radio.Group disabled={!connectedToChecklist} style={{ width: '100%' }}>
              <Row type="flex">
                <Col span={8}>
                  <Radio value={false}>{t(BOOL_VALUES.FALSE.title)}</Radio>
                </Col>
                <Col span={8}>
                  <Radio value>{t(BOOL_VALUES.TRUE.title)}</Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>
        )}

        {checklistAutofailEnabled && (
          <Row className="ant-form-item">
            <Col
              md={{
                span: ratingValuesViews.length > 6 ? 20 : 14,
                offset: ratingValuesViews.length > 6 ? 4 : 10
              }}
            >
              {disabledSave ? (
                <Alert
                  type="warning"
                  showIcon
                  message={t('components.questionModal.form.fields.autofailCriteriesAlert')}
                />
              ) : (
                <Row justify="end" gutter={[8, 16]}>
                  <Col span={24}>
                    <Form.Item
                      name="checklistAutofailValues"
                      rules={[
                        {
                          required: true,
                          message: t('components.questionModal.form.messages.selectAnyAutoF')
                        }
                      ]}
                    >
                      <RateValues
                        ratingValues={ratingValues}
                        ratingValuesViews={ratingValuesViews}
                        scaleType={scaleType}
                        valuesDisplayType={valuesDisplayType}
                        value={checklistAutofailValues}
                        onChange={checklistAutofailValues =>
                          updateQuestionOrBinding({ checklistAutofailValues })
                        }
                        allowMultiple
                        showNull={false}
                      />
                    </Form.Item>
                  </Col>
                  {currentChecklist?.ratingMode === RATING_MODE.PERCENTAGE && (
                    <Col>
                      <Row align="middle" gutter={[8, 0]}>
                        <Col>
                          <Text>
                            {t('components.questionModal.form.fields.autofailTotalScore')}
                          </Text>
                        </Col>
                        <Col>
                          <InputNumber
                            min={0}
                            max={100}
                            formatter={value => `${value}%`}
                            parser={value => value.replace('%', '')}
                            onChange={checklistAutofailResultPercentage =>
                              updateQuestionOrBinding({ checklistAutofailResultPercentage })
                            }
                            value={checklistAutofailResultPercentage || 0}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              )}
            </Col>
          </Row>
        )}

        {connectedToChecklist &&
          currentChecklist?.ratingMode === RATING_MODE.PERCENTAGE &&
          currentChecklist?.isGroupable && (
            <Form.Item
              label={
                <Text strong>
                  {t('components.questionModal.form.fields.questionGroupAutofail')}
                </Text>
              }
              name="groupAutofailEnabled"
            >
              <Radio.Group disabled={!connectedToChecklist} style={{ width: '100%' }}>
                <Row type="flex">
                  <Col span={8}>
                    <Radio value={false}>{t(BOOL_VALUES.FALSE.title)}</Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value>{t(BOOL_VALUES.TRUE.title)}</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          )}

        {groupAutofailEnabled && (
          <Row className="ant-form-item">
            <Col
              md={{
                span: ratingValuesViews.length > 6 ? 20 : 14,
                offset: ratingValuesViews.length > 6 ? 4 : 10
              }}
            >
              {disabledSave ? (
                <Alert
                  type="warning"
                  showIcon
                  message={t(
                    'components.questionModal.form.fields.questionGroupAutofailCriteriesAlert'
                  )}
                />
              ) : (
                <Row justify="end" gutter={[8, 16]}>
                  <Col span={24}>
                    <Form.Item
                      name="groupAutofailValues"
                      rules={[
                        {
                          required: true,
                          message: t('components.questionModal.form.messages.selectAnyAutoF')
                        }
                      ]}
                    >
                      <RateValues
                        ratingValues={ratingValues}
                        ratingValuesViews={ratingValuesViews}
                        scaleType={scaleType}
                        valuesDisplayType={valuesDisplayType}
                        value={groupAutofailValues}
                        onChange={groupAutofailValues =>
                          updateQuestionOrBinding({ groupAutofailValues })
                        }
                        allowMultiple
                        showNull={false}
                      />
                    </Form.Item>
                  </Col>
                  {currentChecklist?.ratingMode === RATING_MODE.PERCENTAGE && (
                    <Col>
                      <Row align="middle" gutter={[8, 0]}>
                        <Col>
                          <Text>
                            {t(
                              'components.questionModal.form.fields.questionGroupAutofailTotalScore'
                            )}
                          </Text>
                        </Col>
                        <Col>
                          <InputNumber
                            min={questionGroup.percentageCalculationStartingWithZero ? -100 : 0}
                            // min={-100}
                            max={100}
                            formatter={value => `${value}%`}
                            parser={value => value.replace('%', '') || 0}
                            onChange={groupAutofailResultPercentage =>
                              updateQuestionOrBinding({ groupAutofailResultPercentage })
                            }
                            value={groupAutofailResultPercentage || 0}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              )}
            </Col>
          </Row>
        )}

        <ColorZonesComponent
          col={{ md: { span: 24 } }}
          max={maxRating}
          min={minRating}
          scaleType={scaleType}
          disabled={isNewQuestion ? questionConnectedToPublishedChecklist : getDisabled()}
          onChange={colorZones => dispatch(updateEditingQuestion({ colorZones }))}
          colorZones={colorZones}
          ratingValues={ratingValues}
          binary={scaleType === SCALE_TYPES.custom_binary.type || ratingValues.length <= 2}
          unary={ratingValues.length === 1}
          forceWithoutMedium={ratingValues.length <= 2}
        />

        <Form.Item
          label={
            <LabelWithInfo
              infoTitle={t('components.questionModal.form.fields.standardCommentsEnabledInfo')}
            >
              {t('components.questionModal.form.fields.standardCommentsEnabled')}
            </LabelWithInfo>
          }
          name="standardCommentsEnabled"
        >
          <Radio.Group style={{ width: '100%' }}>
            <Row type="flex">
              <Col span={8}>
                <Radio value={false}>{t(BOOL_VALUES.FALSE.title)}</Radio>
              </Col>
              <Col span={8}>
                <Radio value>{t(BOOL_VALUES.TRUE.title)}</Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>

        {standardCommentsEnabled && (
          <StandardComments
            ratingValues={ratingValues}
            standardComments={standardComments}
            onChange={debounce(
              standardComments => dispatch(updateEditingQuestion({ standardComments })),
              200
            )}
          />
        )}
      </Form>
    </SModal>
  );
};

export default QuestionModal;
