import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { message, Modal, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { aiTaskAnalyticsErrorsResource } from 'redux/resources/aiTaskAnalyticsError';
import { CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { isEmpty } from 'lodash';
import STable from '../../components/Standard/STable';
import SText from '../../components/Standard/SText';
import ClientInteractionLink from '../../components/ClientInteractionLink';
import { isJsonStr } from '../ClientInteractionsPage/components/ColumnsRender';
import { COMMUNICATION_TYPES_BACK } from '../../core/utils/constants';

const TooltipText = ({ title, length = 60 }) => {
  const stringValue = title.toString();

  // Если длина строки превышает заданное значение
  if (stringValue.length > length) {
    const substring = `${stringValue.substring(0, length)}...`;
    return (
      <Tooltip
        title={
          <div style={{ maxWidth: '600px', maxHeight: '200px', overflowY: 'auto' }}>{title}</div>
        }
        overlayStyle={{ maxWidth: '600px' }}
      >
        <SText
          ellipsis
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            display: 'block'
          }}
        >
          {substring}
        </SText>
      </Tooltip>
    );
  }

  // Если длина строки меньше или равна длине ограничения, просто возвращаем строку
  return (
    <Tooltip title={title}>
      <SText>{stringValue}</SText>
    </Tooltip>
  );
};

const ModalTaskAnalyticsError = ({
  isOpen,
  setIsModalOpen,
  taskId,
  loadAnalyticsError,
  loading,
  analyticsError = [],
  meta = {},
  aiError = []
}) => {
  const { t } = useTranslation();

  const { totalCount = 0, page } = meta;

  useEffect(() => {
    if (taskId) loadAnalyticsError({ taskId, include: 'ai_errors', page_number: 1, page_size: 10 });
  }, [taskId]);

  const handleTableChange = pagination => {
    loadAnalyticsError({
      taskId,
      include: 'ai_errors',
      page_number: pagination.current.toString(),
      page_size: pagination.pageSize.toString()
    });
  };

  const tableConfig = {
    columns: [
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        fixed: 'left',
        width: 30,
        render: (id, record) => {
          const communicationType = record?.attributes?.communicationType;
          const clientInteractionType = record?.attributes?.clientInteractionType;

          const normalizedKey = clientInteractionType?.split('::')[1];

          const decamelizeUpperCase = str =>
            str
              .replace(/([A-Z])([A-Z][a-z])/g, '$1_$2')
              .replace(/([a-z])([A-Z])/g, '$1_$2')
              .toUpperCase();
          return (
            <ClientInteractionLink
              id={id}
              communication={{
                communicationType,
                clientInteractionType: COMMUNICATION_TYPES_BACK[decamelizeUpperCase(normalizedKey)]
              }}
              useDrawer={false}
              isNewPage
            />
          );
        }
      },
      {
        title: 'Текст ошибки',
        dataIndex: 'text',
        key: 'text',
        align: 'center',
        render: (text, record) => {
          if (isEmpty(record?.relationships)) {
            return 'Без ошибки';
          }

          const aiErrorData = record?.relationships?.aiError?.data;

          const errorEntries = Array.isArray(aiErrorData)
            ? aiErrorData.map(item => aiError[item?.id]?.errorText).filter(Boolean)
            : [aiError[aiErrorData?.id]?.errorText].filter(Boolean);

          const textError = errorEntries
            .map(entry => {
              if (isJsonStr(entry)) {
                try {
                  return JSON.stringify(JSON.parse(entry), null, 2);
                } catch {
                  return entry;
                }
              }
              return entry;
            })
            .join('; ');

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0 10px'
              }}
            >
              <TooltipText title={textError}>{textError}</TooltipText>
              {textError && (
                <CopyToClipboard
                  text={textError}
                  onCopy={() => message.success('Текст ошибки скопирован')}
                >
                  <Tooltip title="Скопировать текст ошибки" placement="left">
                    <CopyOutlined />
                  </Tooltip>
                </CopyToClipboard>
              )}
            </div>
          );
        }
      },
      {
        title: 'Тип ошибки',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        render: (type, record) => {
          if (isEmpty(record?.relationships)) {
            return '-';
          }

          const aiErrorData = record?.relationships?.aiError?.data;

          const errorTypes = Array.isArray(aiErrorData)
            ? aiErrorData.map(item => aiError[item?.id]?.errorType).filter(Boolean)
            : [aiError[aiErrorData?.id]?.errorType].filter(Boolean);

          const typeError = errorTypes.join('; ');

          return <TooltipText title={typeError}>{typeError}</TooltipText>;
        }
      }
    ]
  };

  return (
    <Modal
      title={t('aiSettingsPage.taskSettings.communicationList')}
      centered
      visible={isOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      width={755}
    >
      <STable
        dataSource={Object.values(analyticsError)}
        rowKey="id"
        size="small"
        loading={loading}
        columns={tableConfig.columns}
        border
        pagination={{
          total: totalCount,
          current: parseInt(page),
          showSizeChanger: true,
          pageSizeOptions: ['10', '25', '50']
        }}
        onChange={handleTableChange}
      />
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    analyticsError: state.aiTaskAnalyticsErrorResource.byIds.resources,
    aiError: state.aiErrorsResource.byIds,
    meta: state.aiTaskAnalyticsErrorResource.byIds.meta,
    loading: state.aiTaskAnalyticsErrorResource.loading
  };
};

const mapDispatchToProps = {
  loadAnalyticsError: aiTaskAnalyticsErrorsResource.operations.load
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalTaskAnalyticsError);
