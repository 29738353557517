import React, { useState } from 'react';
import SRow from 'components/Standard/SRow';
import { Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import HeaderDatasetAi from './HeaderDatasetAi';
import DatasetAiTable from './DatasetAiTable';
import ApplicationModal from './ApplicationModal';
import QolioAiIcon from '../../components/QolioAiIcon';

const DatasetAiPage = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [createdByText, setCreatedByText] = useState('');
  return (
    <SRow gutter={[0, 16, 16]} padding="16px">
      <ApplicationModal />
      <Col span={24}>
        <Typography.Title level={4}>
          {t('datasetAiPage.title')}
          <QolioAiIcon styles={{ marginLeft: '5px', paddingBottom: '3px' }} alwaysShow />
        </Typography.Title>
      </Col>
      <HeaderDatasetAi
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        searchText={searchText}
        setSearchText={setSearchText}
        setCreatedByText={setCreatedByText}
      />
      <DatasetAiTable
        setIsModalOpen={setIsModalOpen}
        createdByText={createdByText}
        searchText={searchText}
      />
    </SRow>
  );
};

export default DatasetAiPage;
