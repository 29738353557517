export const isCustomFieldVisible = customField => customField && customField?.usedForReviews;

export const getCustomFieldByKey = (customFieldsByKeys, customFieldKey) => {
  return Object.values(customFieldsByKeys).find(item => item?.key === customFieldKey);
};

export const getCustomFieldValue = (customFields, customField) => {
  if (customField?.key && customFields[customField.key] !== undefined) {
    return customFields[customField.key];
  }

  return undefined;
};

export const getCustomFieldsFieldValue = (fieldValues, customField) => {
  if (customField?.key && fieldValues[customField.key] !== undefined) {
    return fieldValues[customField.key]?.value;
  }

  return undefined;
};