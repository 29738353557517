export default {
  dashboardPage: {
    widget: {
      filtersIsNotInstalled: 'Не установлены фильтры',
      unitName: 'Отдел',
      needToInstallFilters: 'Необходимо установить фильтры',
      checkFilters: 'Проверьте фильтры',
      emptyFiltersData: 'По данному набору фильтров данных не найдено',
      emptyName: 'Имя не задано',
      withoutGroups: 'Без группы',
      outdated: {
        title: 'Виджет не поддерживается',
        outdatedFlagsAndCommentsWidgets:
          'Чтобы продолжить работу, создайте XL виджет "Флаги и комментарии"'
      },
      conflicts: {
        title: 'Установленные настройки недоступны',
        description1: 'В связи с изменением настроек профиля вы потеряли доступ к данным.',
        description2:
          'Для изменения настроек виджета нажмите “Очистить виджет” и установите новые фильтры. Для восстановления доступа к ранее установленным настройкам обратитесь к Админу.'
      },
      buttons: {
        more: ' Подробнее',
        export: 'Экспорт в Excel'
      },
      messages: {
        analyticsSuccessfullyExported: 'Аналитика экспортирована',
        exportAnalyticsFailed: 'Не удалось экспортировать аналитику',
        needMoreData: 'Для построения графика нужны оценки минимум за два дня'
      },
      utils: {
        emptyName: 'Название не найдено',
        nameIsNotInstalled: 'Название не установлено:',
        emptyDescription: 'Описание не найдено',
        allTime: 'За все время',
        info: 'Информация'
      },
      modal: {
        tooltip: 'Настройки',
        title: 'Настройки виджета',
        buttons: {
          reset: 'Очистить виджет',
          cancel: 'Отменить',
          save: 'Сохранить'
        },
        messages: {
          widgetSuccessfullyUpdated: 'Виджет обновлен',
          updateWidgetFailed: 'Не удалось обновить виджет',
          requiredField: '(Обязательное поле)'
        },
        form: {
          type: 'Тип данных',
          typePlaceholder: 'Выберите тип данных для отображения',
          name: 'Имя виджета',
          namePlaceholder: 'Введите имя виджета',
          communicationDate: 'Дата коммуникации',
          communicationsCreationPeriod: 'Период создания коммуникаций',
          communicationsCreationPeriodPlaceholder: 'Выберите месяц',
          isWeekendView: 'Показывать выходные',
          isWeekendViewInfo:
            'Позволяет выбрать, будут ли отображаться в виджете столбцы за выходные дни, входящие в заданный период,' +
            ' и будут ли они учитываться при подсчете столбца “Итог”.',
          isWeekendViewPlaceholder: 'Выберите статус отображения выходных дней',
          reviewDate: 'Дата оценки',
          date: 'Дата',
          unit: 'Отдел',
          departmentBeingAudited: 'Проверяемый отдел',
          departmentBeingAuditedPlaceholder: 'Выберите проверяемый отдел',
          unitPlaceholder: 'Выберите отдел',
          operators: 'Операторы',
          operatorsPlaceHolder: 'Выберите операторов',
          reviewers: 'Супервизоры',
          reviewersPlaceHolder: 'Выберите супервизоров',
          checklistDefinition: 'Форма оценки',
          additionalChecklistDefinition: 'Дополнительные формы оценок',
          taskDefinitions: 'Теги',
          taskDefinitionsPlaceHolder: 'Выберите теги',
          isReviewed: 'Статус проверки',
          isReviewedPlaceHolder: 'Выберите статус проверки',
          direction: 'Направление звонка',
          directionPlaceHolder: 'Выберите направление звонка',
          dateFormat: 'Формат отображения',
          dateFormatPlaceHolder: 'Выберите формат отображения'
        }
      }
    },
    dashboard: {
      emptyDashboard: 'Не создано ни одного дашборда',
      emptyDashboardRows: 'Не создано ни одной строки',
      newDashboard: 'Новый дашборд',
      loading: 'Загружаем аналитику',
      messages: {
        dashboardSuccessfullyCreated: 'Дашборд создан',
        createDashboardFailed: 'Не удалось создать дашборд'
      },
      buttons: {
        create: 'Создать'
      },
      reviewsCountShort: 'Пров-ки',
      drawer: {
        title: 'Настройки дашборда',
        dashboards: {
          title: 'Дашборды',
          confirmDelete: {
            title: 'Вы уверены что вы хотите удалить данный дашборд?',
            description: 'После удаления дашборда, его настройки восстановить невозможно'
          },
          messages: {
            rowsLimit: 'Для одного дашборда доступно не более 10 строк',
            dashboardSuccessfullyDeleted: 'Дашборд удален',
            dashboardDeleteFailed: 'Не удалось удалить дашборд',
            dashboardSuccessfullyCopied: 'Дашборд скопирован',
            copyDashboardFailed: 'Не удалось копировать дашборд',
            getPro: 'Перейти на другой тариф чтобы создать больше дашбордов. '
          },
          menu: {
            edit: 'Переименовать дашборд',
            copy: 'Копировать дашборд',
            delete: 'Удалить дашборд'
          },
          buttons: {
            more: 'Подробнее',
            addDashboard: 'Добавить новый дашборд'
          }
        },
        rows: {
          title: 'Виджеты',
          confirmDelete: {
            title: 'Вы уверены что хотите удалить строку?',
            ok: 'Да',
            cancel: 'Нет'
          },
          buttons: {
            addRow: 'Добавить строку'
          },
          rowsCount1: 'Строка с 1 виджетом',
          rowsCountPT1: 'Строка с ',
          rowsCountPT2: 'виджетами'
        }
      }
    },
    tableReviewsCountByOperators: {
      table: {
        columns: {
          dates: 'Даты',
          totalScore: 'Итог',
          reviewsCount: 'Количество проверок',
          averageScore: 'Cреднее'
        }
      },
      viewModes: {
        types: {
          table: {
            name: 'Таблица'
          },
          chart: {
            name: 'График',
            seriesNames: {
              averageScoreByOperator: 'Средний результат по сотруднику',
              totalAverageScore: 'Средний результат по всем сотрудникам'
            },
            yaxis: 'Средний балл',
            scoreStr: 'балла',
            percantageStr: '%',
            table: {
              name: 'Имя',
              description: 'Описание',
              averageScoreStr: 'Средний балл (макс:'
            }
          }
        }
      }
    },
    tableChecklistItemsByOperators: {
      table: {
        columns: {
          questions: 'Критерии',
          groups: 'Группы',
          totalScore: 'Итог',
          average: 'Cреднее'
        }
      },
      viewModes: {
        questions: 'Критерии',
        groups: 'Группы'
      }
    },
    tableChecklistItemsHistory: {
      table: {
        columns: {
          dates: 'Даты',
          totalScore: 'Итог',
          reviewsCount: 'Количество проверок',
          average: 'Cреднее'
        }
      },
      viewModes: {
        questions: 'Критерии',
        groups: 'Группы'
      }
    },
    flagsAndComments: {
      viewModes: {
        operators: 'Операторы',
        units: 'Отделы'
      },
      table: {
        columns: {
          name: 'Имя',
          commentsCount: 'Количество флагов и комментариев'
        }
      }
    },
    tableChecklistsScoresByOperators: {
      firstColumnNames: {
        operators: 'Сотрудники',
        units: 'Отделы'
      },
      columns: {
        totalScore: 'Итог'
      },
      rows: {
        footer: 'Среднее'
      }
    },
    tableReviewsCountByReviewers: {
      changeViewType: 'Сменить режим просмотра',
      firstColumnNames: {
        reviewers: 'Супервизоры',
        units: 'Отделы'
      },
      rows: {
        header: 'Общее время проверок звонков',
        headerInfo:
          'Общая длительность проверенных звонков. Учитывает длительность проверенных звонков столько раз, сколько они подвергались проверке.',
        footer: 'Общее количество проверок'
      },
      viewModes: {
        chart: {
          table: {
            columns: {
              name: 'Имя',
              reviewsCount: 'Проверки',
              reviewsPercentage: '% проверок',
              totalReviewsTime: 'Время'
            },
            rows: {
              total: 'Всего'
            }
          },
          chart: {
            series: {
              reviewed: 'Проверено сотрудником',
              totalReviewed: 'Всего проверенных контактов'
            },
            yAxis: 'Количество проверок'
          }
        }
      }
    },
    tableReviewsWithScores: {
      table: {
        columns: {
          type: 'Тип',
          operatorId: 'Оператор',
          communicationKey: 'Номер телефона / ID Коммуникации',
          totalScore: 'Итог',
          clientInteractionStartedAt: 'Дата коммуникации',
          reviewCreatedAt: 'Дата оценки'
        }
      },
      exportError:
        'Вы пытаетесь скачать более 5000 оценок. Скоратите период для уменьшения количества экспортируемых оценок'
    },
    widgetMeta: {
      longSortMessage: 'Сортировка по критериям может занимать продолжительное время.',
      maxItemsCount: 'Максимальное количество проверок не может превышать 1000.',
      foundReviews: 'Найдено проверок: '
    },
    tableUserFieldAnalytics: {
      changeViewType: 'Сменить режим просмотра',
      columnsNames: {
        operatorId: 'Оператор',
        communicationCount: 'Всего',
        responsesCount: 'С полем',
        fractionCount: 'Доля',
        totalScore: 'Итого'
      },
      columnsTooltips: {
        operatorId: 'Оператор',
        communicationCount: 'Всего коммуникаций сотрудника за выбранных период',
        responsesCount:
          'Кол-во коммуникаций в которых есть выбранные кастомные поля за указанный период',
        fractionCount:
          'Доля коммуникаций с кастомными полями от общего кол-ва коммуникаций за выбранный период',
        fraction:
          'Доля значения кастомного поля от общего числа всех значений выбранных кастомных полей по данному оператору за указанный период'
      },
      rows: {
        total: 'Всего'
      }
    }
  }
};
