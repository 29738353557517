import { Col, Modal, Pagination, Row, Skeleton, Input, Button } from 'antd';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import _, { isEmpty, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid';
import { userCustomFieldsResource } from 'redux/resources/userCustomFields';
import UserCustomFieldsEditor from './UserCustomFieldsEditor';
import { SRow } from '../Standard';

const { Search } = Input;

const UserCustomFields = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  // custom fields errors
  const errors = useSelector(state => state.userCustomFieldsResource.errors);
  const limitExceeded = errors.some(
    item => item.message && item.message.includes('field limit exceeded')
  );
  const incorrectFormat = errors.some(
    item => item.message && item.message.includes('incorrect characters in key field')
  );
  const keyOrNameAlreadyExists = errors.some(
    item => item.message && item.message.includes('field with such name/key already exists')
  );

  const limitFields = errors[0]?.fieldsLimit;
  const loading = useSelector(state => state.userCustomFieldsResource.loading);
  const customFields = useSelector(state => Object.values(state.userCustomFieldsResource.byIds));
  const integrations = useSelector(
    state => Object.values(state.integrationsResource.byIds),
    isEqual
  );
  const [editingCustomField, setEditingCustomField] = useState(null);
  const [addingCustomField, setAddingCustomField] = useState(null);
  const [search, setSearch] = useState('');

  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10
  });

  function loadUserCustomFieldsData(page, pageSize) {
    dispatch(
      userCustomFieldsResource.operations.load({
        organization_id: organizationId,
        page_number: page,
        page_size: pageSize,
        sort: '-created_at',
        search_input: search
      })
    ).then(resource => {
      setPagination({
        current: resource?.meta?.page,
        pageSize,
        total: resource?.meta?.totalPages
      });
    });
  }

  useEffect(() => {
    loadUserCustomFieldsData(1, 10);
  }, []);

  const onPaginationChange = (page, pageSize) => {
    loadUserCustomFieldsData(page, pageSize);
  };

  const showLimitModal = () => {
    return Modal.confirm({
      title: t(
        'integrationsSettingsPage.integrationPage.customFieldsSettings.exceededLimitModal.title'
      ),
      okButtonProps: { style: { display: 'none' } },
      cancelButtonProps: { style: { display: 'none' } },
      content: (
        <div>
          <p className="ant-modal-confirm-content">
            {limitFields ? (
              <div>
                {t(
                  'integrationsSettingsPage.integrationPage.customFieldsSettings.exceededLimitModal.contentStart'
                )}
                {`${limitFields}.`}
              </div>
            ) : null}
            {t(
              'integrationsSettingsPage.integrationPage.customFieldsSettings.exceededLimitModal.contentEnd'
            )}
          </p>
          <div
            className="ant-modal-confirm-btns"
            style={{ flexWrap: 'nowrap', display: 'flex', alignItems: 'center' }}
          >
            <Button type="primary" onClick={() => Modal.destroyAll()}>
              {t(
                'integrationsSettingsPage.integrationPage.customFieldsSettings.exceededLimitModal.ok'
              )}
            </Button>
          </div>
        </div>
      )
    });
  };

  const validationModal = text => {
    return Modal.confirm({
      title: t(
        'integrationsSettingsPage.integrationPage.customFieldsSettings.validationModal.title'
      ),
      okButtonProps: { style: { display: 'none' } },
      cancelButtonProps: { style: { display: 'none' } },
      content: (
        <div>
          <p className="ant-modal-confirm-content">{t(text)}</p>
          <div
            className="ant-modal-confirm-btns"
            style={{ flexWrap: 'nowrap', display: 'flex', alignItems: 'center' }}
          >
            <Button type="primary" onClick={() => Modal.destroyAll()}>
              {t(
                'integrationsSettingsPage.integrationPage.customFieldsSettings.exceededLimitModal.ok'
              )}
            </Button>
          </div>
        </div>
      )
    });
  };

  useEffect(() => {
    // Превышен лимит пользовательских полей
    if (limitExceeded) {
      return showLimitModal();
    }
    // Неверный формат ключа
    if (incorrectFormat) {
      return validationModal(
        'integrationsSettingsPage.integrationPage.customFieldsSettings.validationModal.incorrectFormat'
      );
    }
    // Такое имя или ключ уже существуют
    if (keyOrNameAlreadyExists) {
      return validationModal(
        'integrationsSettingsPage.integrationPage.customFieldsSettings.validationModal.keyOrNameAlreadyExists'
      );
    }
  }, [errors]);

  const onDeleteCustomField = customField => {
    if (customField.id === addingCustomField?.id) {
      return setAddingCustomField(null);
    }
    Modal.confirm({
      title: `${t(
        'integrationsSettingsPage.integrationPage.customFieldsSettings.deleteConfirm.title'
      )} ${customField.name}?`,
      okText: t(
        'integrationsSettingsPage.integrationPage.customFieldsSettings.deleteConfirm.okText'
      ),
      okType: 'danger',
      onOk: () => {
        dispatch(userCustomFieldsResource.operations.deleteById({ id: customField.id }));
        setAddingCustomField(null);
        setEditingCustomField(null);
      }
    });
  };

  const createCustomField = async customField => {
    try {
      const response = await dispatch(
        userCustomFieldsResource.operations.create({
          name: customField.name,
          field_key: customField.key,
          field_type: customField.fieldType,
          usage_for_ai: customField.usedForAi,
          usage_for_reviews: customField.usedForReviews,
          usage_for_filters: customField.usedForFilters,
          organization_id: organizationId
        })
      );
      if (response) {
        return setAddingCustomField(null);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const updateCustomField = async customField => {
    try {
      const response = await dispatch(
        userCustomFieldsResource.operations.updateById({
          id: customField.id,
          actions: {
            update_name: customField.name,
            update_key: customField.key,
            update_type: customField.fieldType,
            update_used_for_reviews: customField.usedForReviews,
            update_used_for_ai: customField.usedForAi,
            update_used_for_filters: customField.usedForFilters
          }
        })
      );

      if (response) {
        return setEditingCustomField(null);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const onSubmitCustomField = async customField => {
    // create custom field
    if (customField.id === addingCustomField?.id) {
      return createCustomField(customField);
    }
    // update custom field
    if (customField.id === editingCustomField?.id) {
      return updateCustomField(customField);
    }
    setEditingCustomField(customField);
  };

  const onAddCustomField = () => {
    setAddingCustomField({
      id: uniqid(),
      fieldType: null,
      key: '',
      name: '',
      usedForFilters: true,
      usedForReviews: true,
      usedForAi: false,
      newField: true
    });
  };

  return (
    <SCard bordered shadowed>
      <Row>
        <Col>
          <Search
            placeholder=""
            value={search}
            onChange={e => setSearch(e.target.value)}
            onSearch={() => loadUserCustomFieldsData(1, pagination.pageSize)}
            enterButton
            loading={loading}
          />
        </Col>
        <Col span={24}>
          <Row gutter={[0, 32]} style={{ marginBottom: '-16px', marginTop: '16px' }}>
            {loading ? (
              <Skeleton active />
            ) : (
              customFields.map(customField => (
                <UserCustomFieldsEditor
                  key={customField.id}
                  customField={customField}
                  addingId={addingCustomField?.id}
                  editingId={editingCustomField?.id}
                  onDelete={onDeleteCustomField}
                  onSubmit={onSubmitCustomField}
                  integration={_.keyBy(integrations, 'id')[customField?.originIntegrationId]?.name}
                  setEditingCustomField={setEditingCustomField}
                  showLimitModal={showLimitModal}
                />
              ))
            )}

            {!isEmpty(addingCustomField) && (
              <UserCustomFieldsEditor
                customField={addingCustomField}
                addingId={addingCustomField?.id}
                editingId={editingCustomField?.id}
                onDelete={onDeleteCustomField}
                onSubmit={onSubmitCustomField}
                integration={
                  _.keyBy(integrations, 'id')[addingCustomField?.originIntegrationId]?.name
                }
                setEditingCustomField={setEditingCustomField}
                showLimitModal={showLimitModal}
              />
            )}

            <Col span={24}>
              <Row justify="end">
                <Col>
                  {isEmpty(addingCustomField) && isEmpty(editingCustomField) && (
                    <SButton onClick={onAddCustomField} type="primary" size="big" width="230px">
                      {t('integrationsSettingsPage.integrationPage.customFieldsSettings.addField')}
                    </SButton>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <SRow type="flex" justify="end" style={{ marginTop: '20px' }} gutter={[0, 16]}>
            <Pagination
              size="small"
              current={pagination.current}
              total={pagination.total * pagination.pageSize}
              pageSize={pagination.pageSize}
              onChange={onPaginationChange}
              showSizeChanger
              onShowSizeChange={onPaginationChange}
              pageSizeOptions={['10', '25', '50']}
            />
          </SRow>
        </Col>
      </Row>
    </SCard>
  );
};

export default UserCustomFields;
